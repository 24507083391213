<template>
<div class="relative py-16 bg-white overflow-hidden">
  <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
  </div>
  <div class="relative px-4 sm:px-6 lg:px-8">
    <div class="text-lg mx-auto mb-6">
      <h1 class="mt-6 mb-10 text-4xl text-center leading-10 font-extrabold tracking-tight text-grblack sm:text-5xl sm:leading-10">Design Principles</h1> 
      <p class="text-xl text-gray-500 leading-8">In our 15+ years of designing and redesigning ez2view, we've settled on some core concepts that apply universally across the software. The sections below will walk you through each of these concepts in more detail so that you can understand the basics of its interface and functionality in order for you to navigate through the software.</p>
      <br/>
    </div>

    <ul class="grid grid-cols-1 gap-6 lg:grid-cols-2 xl:grid-cols-3">

      <router-link to="/design-principles/builddashboard" class="block">
      <li class="h-full col-span-1 bg-white rounded-lg shadow hover:bg-gray-100">
        <div class="w-full flex items-center justify-between p-6 space-x-6">
          <div class="flex-1">
            <div class="flex items-center space-x-3">
              <h3 class="text-grblack xl:text-xl leading-6 font-bold hover-text-grblue lg:text-lg">Build Your Own Dashboard</h3>
            </div>
            <p class="mt-1 text-gray-500 text-sm leading-5">An introduction to widgets, windows and layouts. </p>
          </div>
        </div>
      </li>
      </router-link>

      <router-link to="/design-principles/pointintime" class="block">
      <li class="h-full col-span-1 bg-white rounded-lg shadow hover:bg-gray-100">
        <div class="w-full flex items-center justify-between p-6 space-x-6">
          <div class="flex-1">
            <div class="flex items-center space-x-3">
              <h3 class="text-grblack xl:text-xl leading-6 font-bold hover-text-grblue lg:text-lg">Point in Time View</h3>
            </div>
            <p class="mt-1 text-gray-500 text-sm leading-5">How the concept of time works in ez2view.</p>
          </div>
        </div>
      </li>
      </router-link>

      <router-link to="/design-principles/hyperlinks" class="block">
      <li class="h-full col-span-1 bg-white rounded-lg shadow hover:bg-gray-100">
        <div class="w-full flex items-center justify-between p-6 space-x-6">
          <div class="flex-1">
            <div class="flex items-center space-x-3">
              <h3 class="text-grblack xl:text-xl leading-6 font-bold hover-text-grblue lg:text-lg">Context-sensitive Linkages</h3>
            </div>
            <p class="mt-1 text-gray-500 text-sm leading-5">Dig deeper through hyperlinks.</p>
          </div>
        </div>
      </li>
      </router-link>

      <router-link to="/design-principles/ez2viewexplorer" class="block">
      <li class="h-full col-span-1 bg-white rounded-lg shadow hover:bg-gray-100">
        <div class="w-full flex items-center justify-between p-6 space-x-6">
          <div class="flex-1">
            <div class="flex items-center space-x-3">
              <h3 class="text-grblack xl:text-xl leading-6 font-bold hover-text-grblue lg:text-lg">ez2view Explorer</h3>
            </div>
            <p class="mt-1 text-gray-500 text-sm leading-5">Your central point of navigation.</p>
          </div>
        </div>
      </li>
      </router-link>

      <router-link to="/design-principles/inbuiltalerts" class="block">
      <li class="h-full col-span-1 bg-white rounded-lg shadow hover:bg-gray-100">
        <div class="w-full flex items-center justify-between p-6 space-x-6">
          <div class="flex-1">
            <div class="flex items-center space-x-3">
              <h3 class="text-grblack xl:text-xl leading-6 font-bold hover-text-grblue lg:text-lg">Alerting Colours and Icons</h3>
            </div>
            <p class="mt-1 text-gray-500 text-sm leading-5">Grabbing your attention when something happens.</p>
          </div>
        </div>
      </li>
      </router-link>

    <router-link to="/design-principles/customalerts" class="block h-full">
    <li class="h-full col-span-1 bg-white rounded-lg shadow hover:bg-gray-100">
        <div class="w-full flex items-center justify-between p-6 space-x-6">
          <div class="flex-1">
            <div class="flex items-center space-x-3">
              <h3 class="text-grblack xl:text-xl leading-6 font-bold hover-text-grblue lg:text-lg">Customising Alerts</h3>
            </div>
            <p class="mt-1 text-gray-500 text-sm leading-5">Configure your own custom alerts.</p>
          </div>
        </div>
      </li>
      </router-link>

    <router-link to="/design-principles/quickaccessbuttons" class="block h-full">
    <li class="h-full col-span-1 bg-white rounded-lg shadow hover:bg-gray-100">
        <div class="w-full flex items-center justify-between p-6 space-x-6">
          <div class="flex-1">
            <div class="flex items-center space-x-3">
              <h3 class="text-grblack xl:text-xl leading-6 font-bold hover-text-grblue lg:text-lg">Quick Access Buttons</h3>
            </div>
            <p class="mt-1 text-gray-500 text-sm leading-5">Essential functionality for each widget.</p>
          </div>
        </div>
    </li>
    </router-link>

    <router-link to="/design-principles/sharingpreferences" class="block h-full">
    <li class="h-full col-span-1 bg-white rounded-lg shadow hover:bg-gray-100">
        <div class="w-full flex items-center justify-between p-6 space-x-6">
          <div class="flex-1">
            <div class="flex items-center space-x-3">
              <h3 class="text-grblack xl:text-xl leading-6 font-bold hover-text-grblue lg:text-lg">Sharing Preferences</h3>
            </div>
            <p class="mt-1 text-gray-500 text-sm leading-5">Personalisations and other saved settings.</p>
          </div>
        </div>
    </li>
    </router-link>

    <router-link to="/design-principles/privatedata" class="block h-full">
    <li class="h-full col-span-1 bg-white rounded-lg shadow hover:bg-gray-100">
      <div class="w-full flex items-center justify-between p-6 space-x-6">
        <div class="flex-1">
          <div class="flex items-center space-x-3">
            <h3 class="text-grblack xl:text-xl leading-6 font-bold hover-text-grblue lg:text-lg">Adding Private Data</h3>
          </div>
          <p class="mt-1 text-gray-500 text-sm leading-5">Gaining value from your private AEMO data.</p>
        </div>
      </div>
    </li>
    </router-link>

    </ul>

  </div>
</div>

</template>


<script>
export default {
    title: 'Design Principles'
};
</script>