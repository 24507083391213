<template>
    <div class="relative py-16 bg-white overflow-hidden">
    <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
    </div>
    <div class="relative px-4 sm:px-6 lg:px-8">
        <div class="text-lg mx-auto mb-6">
            <router-link to="/widget-guides" class="block"><p class="text-base text-center leading-4 text-grblue font-semibold tracking-wide hover:underline">Widget Guides</p></router-link>
            <h1 class="mt-4 mb-10 text-4xl text-center leading-10 font-extrabold tracking-tight text-grblack sm:text-5xl sm:leading-10">NEM Schematic</h1>
            <p class="text-xl text-gray-500 leading-8 font-semibold">The NEM Schematic widget displays basic price and demand summaries for each region, along with Interconnector flows between regions.  </p>
            <div class="text-gray-500 mx-auto leading-7 font-normal text-lg">
                <br/>
                <img class="w-1/3 rounded-m mx-auto" src="@/assets/nemschematic-image1.png" alt="NEM Schematic Widget">
                <br/>
                <h2 class="mt-16 mb-4 text-4xl leading-12 font-bold tracking-tight text-grblack lg:leading-4">Description</h2>
                <p> This widget shows one box for each Region in the NEM, laid out in relative geographic position - QLD at the top and TAS at the bottom. Each box contains the dispatch price and the dispatch target demand for the region in the current dispatch interval.</p>
                <br/>
                <p>The Interconnector icons on the NEM Summary Schematic indicate the direction of flow, and the MW flow on the interconnector; a bar across this icon signifies that the interconnector is flowing at its limit.</p>
                <br/>
                <p>The NEM Summary Schematic uses colour to highlight changing data values. The thresholds for each of these colours are described in our <router-link to="/design-principles/inbuiltalerts" class="text-blue-600 hover:underline">Alerting Colours and Icons</router-link> page.</p>
                <br/>
            </div>
        </div>
    </div>
    </div>
</template>

<script>
export default {
    title: 'NEM Schematic'
};
</script>