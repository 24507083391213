<template>
  <div class="hidden md:flex md:flex-shrink-0">
    <div class="flex flex-col w-48 lg:w-80">
      <div class="flex flex-col h-0 flex-1">
        <div class="flex-1 flex flex-col pb-4 bg-grblack-300">
          <nav class="mt-8 flex-1 px-2">
            <menu-items />
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MenuItems from "@/components/MenuItems.vue";

export default {
  components: {
    MenuItems
  }  
}
</script>

<style scoped>
.bg-grblack {
  /* background-color: #7E7481; */
  background-color: rgba(64, 59, 65, 0.7);
}

.text-grblack {
  color: #403b41;
}

.bg-grgrey {
  background-color: #f0f0f0;
}
</style>