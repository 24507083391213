se
<template>
  <div class="relative py-16 bg-white overflow-hidden">
    <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full"></div>
    <div class="relative px-4 sm:px-6 lg:px-8">
      <div class="text-lg mx-auto mb-6">
        <router-link to="/widget-guides" class="block"><p class="text-base text-center leading-4 text-grblue font-semibold tracking-wide hover:underline">Widget Guides</p></router-link>
        <h1 class="mt-4 mb-10 text-4xl text-center leading-10 font-extrabold tracking-tight text-grblack sm:text-5xl sm:leading-10">Unit Dashboard</h1>
        <p class="text-xl text-gray-500 leading-8 font-semibold"> 
        The ‘Unit Dashboard’ widget is intended to provide a focused view combining various important data relevant to the operation of a particular unit (i.e. DUID) operating in ENERGY or FCAS markets.  
        The widget is designed: 
        <ul>
          <li>To deliver value in Real Time mode, even if you only have ‘Real Time Public’ data  (though greater value with ‘Private Data’ ); and</li>
          <li>To deliver value in Time-Travel mode with the advantage of ‘Next Day Public’ data .</li>
        </ul>
        </p>
        <div class="text-gray-500 mx-auto leading-7 font-normal text-lg">
          <br />
          <img class="w-3/4 rounded-m mx-auto" src="@/assets/unitdashboard-image20.png" alt="Unit Dashboard WANDB1" />
          <br />
          <h2 class="mt-16 mb-4 text-4xl leading-12 font-bold tracking-tight text-grblack lg:leading-4">Widget Structure</h2>
          <p>
            The widget is designed to show relevant data:
            <ul>
              <li>For your choice of commodity (ENERGY is default, but select a given FCAS market to see that);</li>
              <li>A multi-commodity view will be added in later.</li>
            </ul><br>
            For a selected view, the layout is comprised of two panels:
            <ul>
              <li>The multi-facet chart on the left provides the ability to view relevant data over a longer time period (many hours or several days);</li>
              <li>Whereas the table on the right is focused in on several dispatch intervals (the focus of this table can be changed by clicking on a certain dispatch interval in the chart).</li>
            </ul><br>
            It's important to note that bidding data is only published by the AEMO at the end of the trading day - 4:00AM the following morning after the calendar day. As such, bidding data does not appear when you open this widget in real-time mode.
          </p>
          <br />
          <h2 class="mt-16 mb-4 text-4xl leading-12 font-bold tracking-tight text-grblack lg:leading-4">Unit ID</h2>
          <br />
          <img class="w-1/6 rounded-m mx-auto" src="@/assets/unitdashboard-image21.png" alt="Unit Dashboard ID" />
          <br />
          <p>
            In the top-left corner of the widget you will find the Unit Id along with the schedule type for the unit. You can click on the search button next to the Unit ID to change the unit for
            which data is being displayed.
          </p>
          <h2 class="mt-16 mb-4 text-4xl leading-12 font-bold tracking-tight text-grblack lg:leading-4">Chart</h2>
          <br />
          <img class="w-1/2 rounded-m mx-auto" src="@/assets/unitdashboard-image22.png" alt="Unit Dashboard Chart" />
          <br />
          <p>The chart displays a time-series area and line graph where:</p>
          <ul class="pl-12 pt-2 pb-2 list-disc">
            <li>
              The coloured sections of the graph represent the amount of availability bid for each bid band in each period. The colours for each bid band correspond to prices - for the current
              effective bid, these colours are also shown in the table on the right of the screen.
            </li>
            <ul>
              <li class="list-indent">As is the case throughout ez2view, these are shown as at the Regional Reference Node (RRN);</li>
              <li class="list-indent">As is the case throughout ez2view, the colours are shades of four broad colours:</li>
              <ul ul class="pl-12 pt-2 pb-2 list-disc">
                <li class="list-indent2">Pink/Red = above $300/MWh</li>
                <li class="list-indent2">Green = between $0/MWh and $300/MWh</li>
                <li class="list-indent2">Blue = $0/MWh and below</li>
                <li class="list-indent2">Black/Grey = Fixed Load</li>
                <li class="list-indent2">These same colours are shown (for the selected Dispatch Interval) in the table on the right.</li>
              </ul>    
            </ul>
            <li>The solid black line represents a linear extrapolation (i.e. from FinalMW to FinalMW) of the actual output of the unit</li>
            <li>The dashed grey lines shown what NEMDE assumed would be the ramping of the unit for each dispatch interval (i.e. from InitialMW to Target).</li>
            <li>The dashed red line represents the availability of the unit in each dispatch interval.</li>
            <li>The dashed green line shows the ‘<a href="https://wattclarity.com.au/other-resources/glossary/pasa-avail/" class="text-blue-600 hover:underline">PASA Availability</a>.’ of the unit in the bid. </li>   
          </ul>
          <br />
          <img class="rounded-m mx-auto" src="@/assets/unitdashboard-image17.png" alt="Unit Dashboard Chart" />
          <br />
          <p>
            The checkboxes in the toolbar (as shown below) allow you to toggle on/off key series.
             <!-- Note
            <a href="https://wattclarity.com.au/other-resources/glossary/pasa-avail/" class="text-blue-600 hover:underline">this PASA Availability</a> information when interpreting PASA Availability. -->
          </p>
          <br />
          <img class="w-1/3 rounded-m mx-auto" src="@/assets/unitdashboard-image10.png" alt="Unit Dashboard Chart Controls" />
          <br />
          <p>
            If you <b><i>hover your mouse</i></b> over the chart you will see a blue vertical bar and two panels - one above the graph and one below. The bottom panel shows the interval that the cursor is hovered
            over while the panel above the chart displays:
          </p>
          <ul class="pl-12 pt-2 pb-2 list-disc">
            <li>The price range for the price bucket that you are hovering over, and surrounding ranges</li>
            <li>The volumes in each of the price ranges</li>
            <li>The cumulative volume at the top of each range</li>
            <li>The figures for availability, target and actual output for the selected dispatch interval.</li>
          </ul>
          <br />
          <img class="w-1/5 rounded-m mx-auto" src="@/assets/unitdashboard-image14.png" alt="Unit Dashboard Hover" />
          <br />
          <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Activity Bar Charts</h3>
          <p> Underneath the main chart are three bar charts that represent a Gantt Chart style view of three parameters relevant to this particular DUID:
          </p>
          <br />
          <img class="w-2/5 rounded-m mx-auto" src="@/assets/unitdashboard-image6.png" alt="Unit Dashboard Activity Bar" />
          <br />
          <div class="mx-auto flex flex-col">
            <div class="mx-auto">
              <div class="mx-auto px-8 py-8 align-middle inline-block sm:px-8 lg:px-8">
                <div class="mx-auto shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table class="mx-auto divide-y divide-gray-200 border">
                    <thead>
                      <tr>
                        <th class="px-2 py-2 border bg-gray-100 leading-2 text-grblack">
                          <p class="text-lg text-center font-bold">Field</p>
                        </th>
                        <th class="px-2 py-2 bg-gray-100 leading-4 text-grblack bo">
                          <p class="text-lg text-center font-bold">Description</p>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="bg-white border">
                        <td class="px-6 py-4 border whitespace-wrap text-sm leading-5 text-grblack font-bold">Is Constrained</td>
                        <td class="px-6 py-4 border whitespace-wrap text-sm leading-5 text-gray-900">
                          Whether or not the unit appears on the LHS of <a class="text-grblue hover:underline" href="https://wattclarity.com.au/other-resources/glossary/constraints/">a bound constraint equation</a>for the given dispatch interval. 
                          <br><br>For a given dispatch interval, the table view will list the constraint equation(s) that are bound with this DUID on the LHS.
                        </td>
                      </tr>
                      <tr class="bg-white border">
                        <td class="px-6 py-4 border whitespace-wrap text-sm leading-5 text-grblack font-bold">Semi-Dispatch Cap</td>
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                          <a class="text-grblue hover:underline" href="https://wattclarity.com.au/other-resources/glossary/semi-scheduled/">Semi-scheduled</a> receive dispatch instructions comprising of both:
                          <ul class="pl-12 pt-2 pb-2 list-disc">
                            <li>a dispatch Target (in MW) and</li>
                            <li>a Semi-Dispatch Cap (SDC either 1 or 0).</li>
                          </ul>
                          What the Semi-Scheduled Generator is required to do depends on the state of the SDC:<br><br>
                          <b>SDC = 1 (YES)</b><br>
                          If the semi-dispatch cap is set to 1 (Yes) then the generator must cap their output at or below the dispatch level for the dispatch interval.<br><br>
                          <b>SDC = 0 (NO)</b><br>
                          If the semi-dispatch cap is not set the generator is free to generate at any level.  Note, however, that the Target still assumes some relevance in <a class="text-grblue hover:underline" href="https://wattclarity.com.au/other-resources/glossary/semi-scheduled/"> Causer Pays calculations</a> even in these circumstances.<br>
                          The cap could be triggered by either:
                          <ul class="pl-12 pt-2 pb-2 list-disc">
                            <li>Economic Reasons (based on bid pricing); or</li>
                            <li>Via Constraint Equations – which might reflect network limitations (in, or outside, the region) or market related limitations</li>
                          </ul>
                        </td>
                      </tr>
                      <tr class="bg-white border">
                        <td class="px-6 py-4 border whitespace-wrap text-sm leading-5 text-grblack font-bold">Intervention</td>
                        <td class="px-6 py-4 border whitespace-wrap text-sm leading-5 text-gray-900">
                          Occasionally the AEMO is required to directly intervene in dispatch solutions to ensure the security and reliability of the power system, thus altering market outcome otherwise dictated by NEMDE.<br><br>
                          The Intervention activity bar also indicates when the AEMO has intervened.
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <h2 class="mt-16 mb-4 text-4xl leading-12 font-bold tracking-tight text-grblack lg:leading-4">Table</h2>
          <p>
            The right panel of the widget displays a table showing a <b>focused view</b> of data (from a range of tables in the EMMS) relevant to the operation:
            <ul class="pl-12 pt-2 pb-2 list-disc">
              <li>of the selected DUID;</li>
              <li>for the selected commodity (ENERGY by default). </li>
            </ul>
          </p>
          <br />
          <img class="w-1/2 rounded-m mx-auto" src="@/assets/unitdashboard-image19.png" alt="Unit Dashboard Table" />
          <br />
          <p>
            Remember that:
            <ul class="pl-12 pt-2 pb-2 list-disc">
              <li>You can <b><i>change</i> the dispatch interval in focus</b> (i.e. the column highlighted in blue) by clicking on the chart.</li>
              <li>Depending on the ‘now’ point of the window (i.e. Real Time or Time Travelled) and where you have clicked in the chart, the table might show: </li>
              <ul>
                <li class="list-indent">Forecast data (if ahead of the ‘now’ point of the window); or</li>
                <li class="list-indent">Actual Data</li>
              </ul>
            </ul>
          </p>
          <br/>
          <p>
            Within this widget we use left and right text alignment to signify if the data is related to the beginning or end of the dispatch interval, respectively.
            <br><br>The table below describes the fields within the table of the widget:
          </p>
          <div class="mx-auto flex flex-col">
            <div class="mx-auto">
              <div class="mx-auto px-8 py-8 align-middle inline-block sm:px-8 lg:px-8">
                <div class="mx-auto shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table class="mx-auto divide-y divide-gray-100">
                    <thead>
                      <tr>
                        <th class="px-2 py-2 border bg-gray-100 leading-2 text-grblack">
                          <p class="text-lg text-center font-bold">Field</p>
                        </th>
                        <th class="px-2 py-2 border bg-gray-100 leading-4 text-grblack">
                          <p class="text-lg text-center font-bold">Description</p>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="bg-white">
                        <td class="px-6 py-4 border whitespace-wrap text-sm leading-5 text-grblack font-bold">Intervention</td>
                        <td class="px-6 py-4 border whitespace-wrap text-sm leading-5 text-gray-900">
                          Occasionally the AEMO is required to directly intervene in dispatch solutions to ensure the security and reliability of the power system, thus altering market outcome otherwise dictated by NEMDE.
                          <ul class="pl-12 pt-2 pb-2 list-disc">
                            <li>The effect of interventions are typically NEM-wide and multi-commodity.</li>
                            <li>Remember that the Gantt chart indicator on the left will also show (as highlight) which dispatch interval contain interventions.</li>
                          </ul>
                          <b>Dispatch and pricing outcomes are more complex under intervention.</b><br>
                          An important point to understand is that, when there is intervention, the AEMO will typically use separate NEMDE runs:
                          <ul class="pl-12 pt-2 pb-2 list-disc">
                            <li>A ‘what if’ pricing run that will calculate prices – </li>
                            <li>Whereas dispatch will be determined with the ‘with the intervention taken into account’ (i.e. ‘physical’) NEMDE run.</li>
                          </ul>
                        </td>
                      </tr>
                      <tr class="bg-white">
                        <td colSpan="2" class="px-6 py-4 whitespace-wrap text-center border text-sm leading-5 text-base bg-gray-50 text-grblack font-bold">Pricing</td>
                      </tr>
                      <tr class="bg-white">
                        <td class="px-6 py-4 border whitespace-wrap text-sm leading-5 text-grblack font-bold">
                          Region Price
                          <br />
                          (e.g. NSW1 Price)
                        </td>
                        <td class="px-6 py-4 border whitespace-wrap text-sm leading-5 text-gray-900">5 minute dispatch price in $/MWh:
                          <ul class="pl-12 pt-2 pb-2 list-disc">
                            <li>for the region that the unit is in.</li>
                            <li>for the commodity selected.</li>
                          </ul>
                        </td>
                      </tr>
                      <tr class="bg-white">
                        <td class="px-6 py-4 border whitespace-wrap text-sm leading-5 text-grblack font-bold">Physical Run Price</td>
                        <td class="px-6 py-4 border whitespace-wrap text-sm leading-5 text-gray-900">Region price during intervention (if applicable).</td>
                      </tr>
                      <tr class="bg-white">
                        <td class="px-6 py-4 border whitespace-wrap text-sm leading-5 text-grblack font-bold">CPD Price</td>
                        <td class="px-6 py-4 border whitespace-wrap text-sm leading-5 text-gray-900">
                          <a class="text-grblue hover:underline" href="https://wattclarity.com.au/other-resources/glossary/cpd-price/">Connection Point Dispatch Price</a>. Unique to each DUID and reflects both:
                          <ul class="pl-12 pt-2 pb-2 list-disc">
                            <li>for the region that the unit is in.</li>
                            <li>for the commodity selected.</li>
                          </ul>
                        </td>
                      </tr>
                      <tr class="bg-white">
                        <td colSpan="2" class="px-6 py-4 whitespace-wrap text-center border text-base leading-5 bg-gray-50 text-grblack font-bold">Alerts</td>
                      </tr>
                      <tr class="bg-white">
                        <td class="px-6 py-4 border whitespace-wrap text-sm leading-5 text-grblack font-bold">Is Constrained</td>
                        <td class="px-6 py-4 border whitespace-wrap text-sm leading-5 text-gray-900">
                          Marked as yes if the unit appears on the LHS of a bound constraint for the given dispatch interval.
                        </td>
                      </tr>
                      <tr class="bg-white">
                        <td class="px-6 py-4 border whitespace-wrap text-sm leading-5 text-grblack font-bold">Bound constraints</td>
                        <td class="px-6 py-4 border whitespace-wrap text-sm leading-5 text-gray-900">
                          Any  <a class="text-grblue hover:underline" href="https://wattclarity.com.au/other-resources/glossary/constraints/">constraint equation</a>:
                          <ul class="pl-12 pt-2 pb-2 list-disc">
                            <li>That is bound in that dispatch interval;</li>
                            <li>With that DUID on the LHS.</li>
                          </ul>
                          Each constraint equation is hyperlinked to the relevant ‘Constraint Dashboard’ widget.
                        </td>
                      </tr>
                      <tr class="bg-white">
                        <td class="px-6 py-4 border whitespace-wrap text-sm leading-5 text-grblack font-bold">Conformance Obligation</td>
                        <td class="px-6 py-4 border whitespace-wrap text-sm leading-5 text-gray-900">
                          Please refer to <a class="text-grblue hover:underline" href="../../complex-market-concepts/Conformance">this page about <b>Unit Conformance</b></a> (both obligations and status).<br><br>
                          As part of the IESS Rule Change (specifically Aggregate Dispatch Conformance) this has become more complex for certain units, so:
                          <ul class="pl-12 pt-2 pb-2 list-disc">
                            <li>This widget has been upgraded; and</li>
                            <li>This page has been updated.</li>
                          </ul>
                          With respect to this widget:
                          <ul class="pl-12 pt-2 pb-2 list-disc">
                            <li>The logic accounts for both:</li>
                            <ul ul class="pl-12 pt-2 pb-2 list-disc">
                              <li class="list-indent2">Aggregate Conformance (if applicable) and </li>
                              <li class="list-indent2">Semi-Dispatch Cap (if applicable). </li>
                            </ul>
                          </ul>
                        </td>
                      </tr>
                      <tr class="bg-white">
                        <td class="px-6 py-4 border whitespace-wrap text-sm leading-5 text-grblack font-bold">Conformance Status</td>
                        <td class="px-6 py-4 border whitespace-wrap text-sm leading-5 text-gray-900">
                          Please refer to <a class="text-grblue hover:underline" href="../../complex-market-concepts/Conformance">this page about <b>Unit Conformance</b></a> (both obligations and status).<br><br>
                          'Conformance Status' is a six-tier status index set out by the AEMO and applies to Scheduled and Semi-Scheduled units. <br><br>
                          Data shown here:
                          <ul class="pl-12 pt-2 pb-2 list-disc">
                            <li>If you have Public Data:</li>
                            <ul>
                              <li class="list-indent">Can’t be estimated, in Real Time</li>
                              <li class="list-indent">Is estimated for ‘yesterday’ with ‘Next Day Public’ data.</li>
                            </ul>
                            <li>Tooltip will reference if the conformance status is estimated  or found from a private database. </li>
                            <li>If you have Private Data, comes from DISPATCH_UNIT_CONFORMANCE table</li>
                          </ul>
                        </td>
                      </tr>
                      <tr class="bg-white">
                        <td colSpan="2" class="px-6 py-4 whitespace-wrap text-center border text-base leading-5 bg-gray-50 text-grblack font-bold">Dispatch</td>
                      </tr>
                      <tr class="bg-white">
                        <td class="px-6 py-4 border whitespace-wrap text-sm leading-5 text-grblack font-bold">Initial Power & Target Power</td>
                        <td class="px-6 py-4 border whitespace-wrap text-sm leading-5 text-gray-900">
                          This cell provides a view of several different data points for the focused dispatch interval.  
                          <ul class="pl-12 pt-2 pb-2 list-disc">
                            <li>For ENERGY, this shows</li>
                            <ul>
                              <li class="list-indent">InitialMW (i.e. SCADA snapshot of the DUID’s output at the start of the dispatch interval)</li>
                              <li class="list-indent">Target (i.e. output from NEMDE for the end of the dispatch interval)</li>
                              <li class="list-indent">The bounds of possible output for that dispatch interval – limited only* by:</li> 
                              <ul ul class="pl-12 pt-2 pb-2 list-disc">
                                <li class="list-indent2">Ramp rate limitations; and</li>
                                <li class="list-indent2">The lesser of Capacity and Availability.</li>
                              </ul>    
                            </ul>
                            <li>For FCAS, ramp rates are not relevant.</li>
                          </ul>
                          (* note that there are other possible limitations on output trajectory, but we have deliberately omitted them from these measures shown on the screen)<br>
                          More details on the calculation are available in the tooltip.
                        </td>
                      </tr>
                      <tr class="bg-white">
                        <td class="px-6 py-4 border whitespace-wrap text-sm leading-5 text-grblack font-bold">Actual</td>
                        <td class="px-6 py-4 border whitespace-wrap text-sm leading-5 text-gray-900">
                          Unit output at the end of the interval, based on SCADA reading at the start of the next interval (i.e. the InitialMW for the next internal).
                        </td>
                      </tr>
                      <tr class="bg-white">
                        <td class="px-6 py-4 border whitespace-wrap text-sm leading-5 text-grblack font-bold">Dispatch Error</td>
                        <td class="px-6 py-4 border whitespace-wrap text-sm leading-5 text-gray-900">
                          As defined by the AEMO, the <a class="text-grblue hover:underline" href="https://wattclarity.com.au/other-resources/glossary/dispatch-error/">Dispatch Error</a> is the deviation away 
                          from Target of the unit at the end of the dispatch interval.
                        </td>
                      </tr>
                      <tr class="bg-white">
                        <td colSpan="2" class="px-6 py-4 whitespace-wrap text-center border text-base leading-5 bg-gray-50 text-grblack font-bold">Capacity (load/gen)</td>
                      </tr>
                      <tr class="bg-white">
                        <td class="px-6 py-4 border whitespace-wrap text-sm leading-5 text-grblack font-bold">Capacity</td>
                        <td class="px-6 py-4 border whitespace-wrap text-sm leading-5 text-gray-900">The Maximum/Minimum Capacity of the unit used for bid validation.</td>
                      </tr>
                      <tr class="bg-white">
                        <td class="px-6 py-4 border whitespace-wrap text-sm leading-5 text-grblack font-bold">Available</td>
                        <td class="px-6 py-4 border whitespace-wrap text-sm leading-5 text-gray-900">The availability of the unit for the given dispatch interval.</td>
                      </tr>
                      <tr class="bg-white">
                        <td class="px-6 py-4 border whitespace-wrap text-sm leading-5 text-grblack font-bold">Unavailable</td>
                        <td class="px-6 py-4 border whitespace-wrap text-sm leading-5 text-gray-900">
                          The MW amount unavailable of the unit for the given dispatch interval. Calculated at Min/Max Capacity - Available.
                        </td>
                      </tr>
                      <tr class="bg-white">
                        <td class="px-6 py-4 border whitespace-wrap text-sm leading-5 text-grblack font-bold">Source</td>
                        <td class="px-6 py-4 border whitespace-wrap text-sm leading-5 text-gray-900">
                          The source of the 'available' data.  
                          <ul class="pl-12 pt-2 pb-2 list-disc">
                            <li>For Scheduled units, the unit's availability (in MW) is determined by the bid in effect.</li>
                            <li>For Semi-Scheduled units, the calculation is more complex (
                              <a href="https://wattclarity.com.au/articles/2023/08/what-inputs-and-processes-determine-a-semi-scheduled-units-availability/" class="text-blue-600 hover:underline">
                                and is described here</a>).</li>
                          </ul>
                          If you hover over the data in this row, a tooltip with further details about the availability source will appear.
                          <br />
                          <br />
                          <img class="w-1/4 rounded-m mx-auto" src="@/assets/unitdashboard-image16.png" alt="Unit Dashboard Table" />
                          <br />
                          Note that FCAS availability may be restricted in the dispatch engine for additional reasons.
                        </td>
                      </tr>
                      <tr class="bg-white">
                        <td colSpan="2" class="px-6 py-4 whitespace-wrap text-center border text-base leading-5 bg-gray-50 text-grblack font-bold">Bid</td>
                      </tr>
                      <tr class="bg-white">
                        <td class="px-6 py-4 border whitespace-wrap text-sm leading-5 text-grblack font-bold">Offer Date</td>
                        <td class="px-6 py-4 border whitespace-wrap text-sm leading-5 text-gray-900">The submission timestamp of the bid in effect for the given dispatch interval.</td>
                      </tr>
                      <tr class="bg-white">
                        <td class="px-6 py-4 border whitespace-wrap text-sm leading-5 text-grblack font-bold">Reason</td>
                        <td class="px-6 py-4 border whitespace-wrap text-sm leading-5 text-gray-900">
                          Bid or rebid reason as submitted by the participant (or their auto-bidding software) to the AEMO.
                        </td>
                      </tr>
                      <tr class="bg-white">
                        <td class="px-6 py-4 border whitespace-wrap text-sm leading-5 text-grblack font-bold">Age</td>
                        <td class="px-6 py-4 border whitespace-wrap text-sm leading-5 text-gray-900">Age (in number of dispatch intervals) that the bid has been in effect for.</td>
                      </tr>
                      <tr class="bg-white">
                        <td class="px-6 py-4 border whitespace-wrap text-sm leading-5 text-grblack font-bold">Bid Prices</td>
                        <td class="px-6 py-4 border whitespace-wrap text-sm leading-5 text-gray-900">
                          The rows represent the ten bid bands of the bid in effect for that particular dispatch interval:
                          <ul class="pl-12 pt-2 pb-2 list-disc">
                            <li>Set for the focused ‘Trading Day’ at Gate Closure #1 (12:30 the prior day) and fixed for that day.</li>
                            <li>As done throughout ez2view, these bid bands are shown as at the RRN.</li>
                          </ul>
                          And the columns represent the MW amount allocated in each band for the given dispatch interval. A grey bar in the background of these 
                          cells represents the amount dispatched of the amount allocated within the bid band.
                          <br />
                          <br />
                          <img class="w-1/2 rounded-m mx-auto" src="@/assets/unitdashboard-image18.png" alt="Unit Dashboard Bids" />
                          <br />
                          The coloured squares shown here are the same as what is shown in main chart within this widget.
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  title: 'Unit Dashboard'
};
</script>
