<template>
    <div class="relative py-16 bg-white overflow-hidden">
    <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
    </div>
    <div class="relative px-4 sm:px-6 lg:px-8">
        <div class="text-lg mx-auto mb-6">
            <router-link to="/widget-guides" class="block"><p class="text-base text-center leading-4 text-grblue font-semibold tracking-wide hover:underline">Widget Guides</p></router-link>
            <h1 class="mt-4 mb-10 text-4xl text-center leading-10 font-extrabold tracking-tight text-grblack sm:text-5xl sm:leading-10">Constraint Set Details</h1>
            <p class="text-xl text-gray-500 leading-8 font-semibold">The Constraint Set Details widget shows information about a selected constraint set; including constraint equations belonging to this set and the set's invocation schedule.  </p>
            <div class="text-gray-500 mx-auto leading-7 font-normal text-lg">
                <br/>
                <img class="w-2/3 rounded-m mx-auto" src="@/assets/constraintsetdetails-image1.png" alt="Gen Change Widget">
                <br/>
                <h2 class="mt-16 mb-4 text-4xl leading-12 font-bold tracking-tight text-grblack lg:leading-4">Description</h2>
                <p>The Constraint Set Details view shows details about the selected constraint set; including constraint equations belonging to this set and the sets invocation schedule.</p>
                <br/>
                <img class="w-1/2 rounded-m mx-auto" src="@/assets/constraintsetdetails-image2.png" alt="Gen Change Widget">
                <br/>
                <h2 class="mt-16 mb-4 text-4xl leading-12 font-bold tracking-tight text-grblack lg:leading-4">Constraint Set Id</h2>
                <br/>
                <img class="w-1/5 rounded-m mx-auto" src="@/assets/constraintsetdetails-image3.png" alt="Gen Change Widget">
                <br/>
                <p>The top-left corner of the widget displays the Id for the chosen constraint set. You can click on the small search button next to the constraint set Id to change the set that data is being displayed for in the widget.</p>
                <h2 class="mt-16 mb-4 text-4xl leading-12 font-bold tracking-tight text-grblack lg:leading-4">Constraint Set Details</h2>
                <br/>
                <img class="w-2/5 rounded-m mx-auto" src="@/assets/constraintsetdetails-image4.png" alt="Gen Change Widget">
                <br/>
                <p>This panel of the widget displays general information about the constraint set as published by the AEMO. This includes a description of the constraint, regions covered, and date which the constraint takes effect.</p>
                <h2 class="mt-16 mb-4 text-4xl leading-12 font-bold tracking-tight text-grblack lg:leading-4">Constraint Set Invocation Schedule</h2>
                <br/>
                <img class="w-1/3 rounded-m mx-auto" src="@/assets/constraintsetdetails-image5.png" alt="Gen Change Widget">
                <br/>
                <p>The bottom left corner shows the constraint set invocation schedule, indicating when the constraint set will be invoked in the future; and also invocations 7 days back from the current point in time. If the constraint set is currently invoked a row in the schedule will be highlighted in orange. The schedule table shows the start and end dispatch interval, how long before the constraint set will be invoked and how long the constraint set will be invoked for. </p>
                <h2 class="mt-16 mb-4 text-4xl leading-12 font-bold tracking-tight text-grblack lg:leading-4">Table of Constraint Equations in Set</h2>
                <br/>
                <img class="w-2/5 rounded-m mx-auto" src="@/assets/constraintsetdetails-image6.png" alt="Gen Change Widget">
                <br/>
                <p>The top right corner shows a list of the constraint equations belonging to the constraint set. Each constraint set shows a brief summary including LHS and RHS values, Current Marginal Value, Constraint Weight and a short description. Selecting a row in the table will show chart for the selected constraint equation in the bottom right corner of the screen.</p>
                <h2 class="mt-16 mb-4 text-4xl leading-12 font-bold tracking-tight text-grblack lg:leading-4">Constraint Equation Values Chart</h2>
                <br/>
                <img class="w-2/5 rounded-m mx-auto" src="@/assets/constraintsetdetails-image7.png" alt="Gen Change Widget">
                <br/>
                <p>When you click on a constraint equation in the table above, the chart will display a a time-series line graph of the actual and forecasted LHS and RHS values for the equation selected. A vertical dotted line represent the current point in time, and the vertical dashed line represents where the P5 forecasts converge to P30 forecasts. </p>
                <br/>
                <p>Hovering the mouse on the chart will show the values for LHS and RHS at the point in time the mouse cursor is over. </p>
            </div>
        </div>
    </div>
    </div>
</template>

<script>
export default {
    title: 'Constraint Set Details'
};
</script>