<template>
    <div class="relative py-16 bg-white overflow-hidden">
    <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
    </div>
    <div class="relative px-4 sm:px-6 lg:px-8">
        <div class="text-lg mx-auto mb-6">
            <router-link to="/widget-guides" class="block"><p class="text-base text-center leading-4 text-grblue font-semibold tracking-wide hover:underline">Widget Guides</p></router-link>
            <h1 class="mt-4 mb-10 text-4xl text-center leading-10 font-extrabold tracking-tight text-grblack sm:text-5xl sm:leading-10">Regional Sensitivities</h1>
            <p class="text-xl text-gray-500 leading-8 font-semibold">The Regional Price Sensitivities widget displays the most recent published price sensitivities for each region, and allows you to compare these values with the corresponding values from previous pre-dispatch runs. </p>
            <div class="text-gray-500 mx-auto leading-7 font-normal text-lg">
                <br/>
                <img class="w-2/3 rounded-m mx-auto" src="@/assets/regionalsensitivities-image1.png" alt="Gen Change Widget">
                <br/>
                <h2 class="mt-16 mb-4 text-4xl leading-12 font-bold tracking-tight text-grblack lg:leading-4">Description</h2>
                <p>The Regional Price Sensitivity screen helps you better understand the implications of these potential variations in demand on trading prices so you can react appropriately. The Price Sensitivities screen allows you to control various aspects of the display as indicated on the screen below. </p>
                <br/>
                <p>For each Region, AEMO publishes a set of pre-dispatch 'sensitivity prices'. These sensitivity prices show AEMO's prediction of the trading price in each Region depending on the occurrence of different 'scenarios'. Each scenario is essentially a different assumption about demand in one or more of the Regions.</p>
                <br/>
                <p>For example, you might want to understand the implications for the NSW price if demand in NSW exceeds forecast by 100MW, or if demand in Victoria is less than forecast by 200MW. </p>
                <br/>
                <h2 class="mt-16 mb-4 text-4xl leading-12 font-bold tracking-tight text-grblack lg:leading-4">Toolbar, Run Table and Run Chart</h2>
                <p>More information about this widget will be published soon. Please <router-link to="/contact-us" class="text-blue-600 hover:underline">contact us</router-link> if you require immediate assistance with this widget.</p>
            </div>
        </div>
    </div>
    </div>
</template>

<script>
export default {
    title: 'Regional Sensitivities'
};
</script>