<template>
    <div class="relative py-16 bg-white overflow-hidden">
    <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
    </div>
    <div class="relative px-4 sm:px-6 lg:px-8">
        <div class="text-lg mx-auto mb-6">
            <router-link to="/widget-guides" class="block"><p class="text-base text-center leading-4 text-grblue font-semibold tracking-wide hover:underline">Widget Guides</p></router-link>
            <h1 class="mt-4 mb-10 text-4xl text-center leading-10 font-extrabold tracking-tight text-grblack sm:text-5xl sm:leading-10">NEM Prices</h1>
            <p class="text-xl text-gray-500 leading-8 font-semibold">The NEM Prices widget shows an overview of the current spot prices for all nine commodity markets for all five regions of the NEM, 
                as well as displaying the flow of all interconnectors. Importantly, this widget also informs you if intervention is invoked by the AEMO for the given dispatch interval, and during such an event, gives you the ability to toggle intervention pricing on and off.</p>
            <div class="text-gray-500 mx-auto leading-7 font-normal text-lg">
                <br/>
                <img class="w-1/2 rounded-m mx-auto" src="@/assets/nemprices-image10.png" alt="Nem prices">
                <br/>
                <h2 class="mt-16 mb-4 text-4xl leading-12 font-bold tracking-tight text-grblack lg:leading-4">Description</h2>
                <p>This web widget displays the live spot prices for energy and the eight FCAS commodity markets for each region. 
                    The regions are laid out in relative geographic position - with QLD at the top and TAS at the bottom. The MW value of interconnector flows are also displayed between each region. </p>
                <br/>
                <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Spot Prices</h3>
                <p>Each large box represents a region of the NEM, with the inner smaller boxes representing the commodity spot prices in that region. The layout for the boxes is shown below:</p>
                <br/>
                <img class="w-3/5 rounded-m mx-auto" src="@/assets/nemprices-image12.png" alt="NEM prices boxes">
                <br/>
                <p>Under certain circumstances, flags will appear next to the regional price to indicate any non-system-normal pricing that is in effect for the dispatch interval, where:</p>
                <ol class="pl-12 pt-2 pb-2 list-disc">
                    <li><b>AP</b> = Administered Pricing in effect</li>
                    <li><b>MPC</b> = Market Price Cap binding </li>
                    <li><b>IRLF</b> = Price Scaling via Inter-regional Loss Factor (IRLF) applied</li>
                </ol>
                <br/>
                <img class="w-1/3 rounded-m mx-auto" src="@/assets/nemprices-image8.png" alt="NEM prices boxes">
                <br/>
                <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Interconnector Flows</h3>
                <br/>
                <img class="w-1/3 rounded-m mx-auto" src="@/assets/nemprices-image4.png" alt="Interconnector Flows">
                <br/>
                <p>The interconnector icons between regions indicate the direction of flow, and the MW flow on the interconnector; a bar across this icon (like shown above) signifies that the interconnector is flowing at its limit. </p>
                <br/>
                <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Special Market States</h3>
                <br/>
                <img class="w-1/4 rounded-m mx-auto" src="@/assets/nemprices-image5.png" alt="Intervention">
                <br/>
                <img class="w-1/4 rounded-m mx-auto" src="@/assets/nemprices-image9.png" alt="Market Suspension">
                <br/>
                <img class="w-1/4 rounded-m mx-auto" src="@/assets/nemprices-image7.png" alt="Administered Prices">
                <br/>
                <p>If a market intervention, market suspension or administered pricing is in effect for the given dispatch interval, a coloured bar with the text will appear like shown in the images above. You will then be able to use a radio button to select if you want the widget to display 'financial' (adjusted) or 'physical' pricing. </p>
                <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Legend</h3>
                <br/>
                <img class="w-1/3 rounded-m mx-auto" src="@/assets/nemprices-image6.png" alt="NEM Prices - Legend">
                <br/>
                <p>The legend as shown above, displays the scale of the colouring used for each spot price. The colouring is based on historical maximum and minimum prices, with the energy price range derived separately to the FCAS price range. </p>
                <br/>
                <p>The change in price from the previous interval is summarised by chevrons if the absolute change is greater than 5% (no chevron if less than 5%). The direction of the change is reflected in the direction of the chevrons. Price change markers follow the design principles <router-link to="/design-principles/InBuiltAlerts" class="inline text-blue-600 hover:underline">explained here</router-link>.</p>
                <br/>
            </div>
        </div>
    </div>
    </div>
</template>

<script>
export default {
    title: 'NEM Prices'
};
</script>