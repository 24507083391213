<template>
    <div class="space-y-1">
        <div v-for="menuItem in menuItems" :key="menuItem.path">
              <router-link
                @click.native="expand(menuItem)"
                :to="`/${menuItem.path}`"
                class="group flex items-center px-2 py-2 text-sm leading-5 font-bold text-white rounded hover-text-grblack hover:bg-white focus:outline-none focus-text-grblack focus:bg-white transition ease-in-out duration-150"
                :class="getTopLevelClass(menuItem)"
              >
                <svg
                  v-if="hasChildren(menuItem)"
                  class="mr-2 h-5 w-5 transform group-hover:text-white group-focus:text-white transition-colors ease-in-out duration-150"
                  :class="isExpanded(menuItem) ? 'text-gray-400 rotate-90' : 'text-gray-300'"
                  viewBox="0 0 20 20"
                >
                  <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
                </svg>
                {{ menuItem.name }}
              </router-link>
              <div v-if="isExpanded(menuItem) && hasChildren(menuItem)" class="mt-1 space-y-1">
                <router-link
                  @click.native="activate(child)"
                  v-for="child in menuItem.children.filter(c => !isHidden(c))"
                  :key="child.path"
                  :to="`/${menuItem.path}/${child.path}`"
                  :class="isActive(child) ? 'bg-white' : ''"
                  class="pl-14 group flex items-center px-2 py-2 text-sm leading-5 font-normal bg-gray-200 text-gray-900 rounded-md hover:text-gray-900 hover:bg-white focus:outline-none focus:bg-white transition focus-font-semibold ease-in-out duration-150"
                >
                  {{ child.name }}
                </router-link>
              </div>
            </div>
    </div>
</template>


<script>
import siteMap from '@/router/siteMap';

export default {
  data() {
    return {
      expanded: undefined,
      active: undefined,
      isOpen: false
    };
  },
  computed: {
    menuItems() {
      return siteMap.filter(m => !m.meta?.hide);
    }
  },
  watch: {
    $route() {
      const parts = this.$route.path.split('/');
      this.active = parts[parts.length - 1];
    }
  },
  methods: {
    hasChildren(menuItem) {
      return menuItem.children && menuItem.children.length > 0 && !menuItem.meta?.hideChildren;
    },
    isExpanded(menuItem) {
      return this.expanded === menuItem.path;
    },
    isActive(menuItem) {
      return this.active === menuItem.path;
    },
    isHidden(menuItem) {
      if (menuItem.meta === undefined) return false;
      if (menuItem.meta?.hide) return true;
      return false;
    },
    expand(menuItem) {
      this.expanded = menuItem.path;
      this.active = menuItem.path;

      if(!this.hasChildren(menuItem))
        this.$emit('menuItemClicked');      
    },
    activate(menuItem) {
      this.active = menuItem.path;
      this.$emit('menuItemClicked');
    },
    getTopLevelClass(menuItem) {
      let classes = '';

      if (!this.hasChildren(menuItem)) classes += ' pl-9';

      if (this.isActive(menuItem)) classes += ' bg-white text-grblack';
      else classes += ' bg-grblack-900';

      return classes;
    }
  },
  created() {
    const parts = this.$route.path.split('/');
    parts.shift();

    const selected = parts[0];

    if (selected) {
      this.expanded = selected;
    }
  }
};
</script>


<style scoped>
.bg-grblack-900 {
  background-color: rgba(64, 59, 65, 0.9);
}

.text-grblack {
  color: #403b41;
}

.bg-grgrey {
  background-color: #f0f0f0;
}

.focus-bg-grblue:focus {
  background-color: #5273aa;
}

.focus-text-grblack:focus {
  color: #403b41;
}

.focus-font-semibold:focus {
  font-weight: 600;
}

.hover-text-grblack:hover {
  color: #403b41;
}
</style>