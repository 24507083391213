<template>
    <div class="relative py-16 bg-white overflow-hidden">
    <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
    </div>
    <div class="relative px-4 sm:px-6 lg:px-8">
        <div class="text-lg mb-2 mx-auto">
            <router-link to="/design-principles" class="block"><p class="text-base text-center leading-4 text-grblue font-semibold tracking-wide hover:underline">Design Principles</p></router-link>
        </div>
        <nav class="border-t border-gray-200 px-4 flex items-center justify-between sm:px-0">
            <div class="-mt-px w-0 flex-1 flex">
                <router-link to="/design-principles/ez2viewexplorer" class="border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-md font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300">
                    <!-- Heroicon name: arrow-narrow-left -->
                    <svg class="mr-3 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z" clip-rule="evenodd" />
                    </svg>
                    <p>Previous: <b> ez2view Explorer</b></p>
                </router-link>
            </div>
            <div class="hidden md:-mt-px md:flex">
            </div>
            <div class="-mt-px w-0 flex-1 flex justify-end">
                <router-link to="/design-principles/customalerts" class="border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-md font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300">
                    <p>Next: <b> Customising Alerts</b></p>
                    <!-- Heroicon name: arrow-narrow-right -->
                    <svg class="ml-3 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd" />
                    </svg>
                </router-link>
            </div>
        </nav>
        <div class="text-lg mx-auto mb-6">
            <h1 class="mt-6 mb-10 text-4xl text-center leading-10 font-extrabold tracking-tight text-grblack sm:text-5xl sm:leading-10">Alerting Colours and Icons</h1>
            <br/>
            <p class="text-xl text-gray-500 leading-8 font-semibold">We strive to draw your attention to what’s important and we do this by using colour, and several other design techniques. Below we have outlined some of these alerting principles so you can familiarise yourself to their meaning. </p>
        </div>
        <div class="text-gray-500 mx-auto leading-7 font-normal text-lg">
            <br/>
            <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Colours used to represent significant change</h3>
            <br/>
            <img class="w-full lg:w-1/2 mx-auto" src="@/assets/inbuiltalerts-image1.png" alt="Colouring to represent signifiant change">
            <br/>
            <p>Across much of the app, we use the colours yellow, orange and red to denote significant changes in bound marginal values.</p>
            <br/>
            <p>These three colours are used when the change in marginal value occurs within these thresholds:</p>
            <ul class="pl-16 pt-2 pb-2 list-disc">
                <li><b class="text-yellow-400">Yellow</b> represents a change between 5% and 10%</li>
                <li><b class="text-orange-400">Orange</b> represents a change between 10% and 15%</li>
                <li><b class="text-red-600">Red</b> represents a change above 15%</li>
            </ul>
            <p>Note that these are applied for both changes up, and changes down.</p>
            <br/>
            <p>Examples of where this colour scheme is used include in the Regional Schematic widgets and the Regional Sensitivities widgets.</p>
            <br/>
            <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Colours used for thermalling</h3>
            <br/>
            <img class="w-full lg:w-1/2 mx-auto" src="@/assets/inbuiltalerts-image2.png" alt="Colouring for thermalling">
            <br/>
            <p>In some widgets we have chosen to use thermal colouring to visualise values within larger ranges. The thermal scale is shown below.</p>
            <br/>
            <img class="mx-auto" src="@/assets/inbuiltalerts-image3.png" alt="Thermal Scale">
            <br/>
            <p>Examples of where you will find thermal colouring used include the Forecast Convergence widget and the Regional Demand widget.</p>
            <br/>
            <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Icons used for constraints binding</h3>
            <br/>
            <img class="w-full lg:w-1/6 mx-auto h-gif2" src="@/assets/inbuiltalerts-image4.gif" alt="Positive Constraint" style="height: 80px; width: 140px; -webkit-font-smoothing: antialised; backface-visibility: hidden; transform: translateZ(0); -webkit-font-smoothing: subpixel-antialiased;">
            <br/>
            <img class="w-full lg:w-1/6 mx-auto h-gif2" src="@/assets/inbuiltalerts-image5.gif" alt="Negative Constraint" style="height: 80px; width: 140px; -webkit-font-smoothing: antialised; backface-visibility: hidden; transform: translateZ(0); -webkit-font-smoothing: subpixel-antialiased;">
            <br/>
            <p>Where elements are affected by bound constraint equations we use the icons below to notify the user of the type of constraint:</p>
            <div class="mx-auto flex flex-col">
                <div class="mx-auto">
                    <div class="mx-auto px-8 py-8 align-middle inline-block sm:px-8 lg:px-8">
                        <div class="mx-auto shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                            <table class="mx-auto divide-y divide-gray-200">
                            <thead>
                                <tr>
                                <th class="px-2 py-2 bg-gray-100 leading-2 text-gray-500">
                                    <p class="text-lg text-center font-bold">Icon</p>
                                </th>
                                <th class="px-2 py-2 bg-gray-100 leading-4 text-gray-500">
                                    <p class="text-lg text-center font-bold">Meaning</p>
                                </th>
                                </tr>
                            </thead>
                            <tbody>
                                <!-- Odd row -->
                                <tr class="bg-white">
                                <td class="px-6 py-4 whitespace-wrap text-sm leading-5 font-medium text-gray-900">
                                    <img class="mx-auto" src="@/assets/inbuiltalerts-image19.png" alt="Bookmark Button">
                                </td>
                                <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-500">
                                    Running with No Bound Constraints
                                </td>
                                </tr>
                                <!-- Even row -->
                                <tr class="bg-white">
                                <td class="px-6 py-4 whitespace-wrap text-sm leading-5 font-medium text-gray-900">
                                    <img class="mx-auto" src="@/assets/inbuiltalerts-image20.png" alt="Capture Button">
                                </td>
                                <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-500">
                                    Not Running with No Bound Constraints
                                </td>
                                <!-- Odd row -->
                                <tr class="bg-white">
                                <td class="px-6 py-4 whitespace-wrap text-sm leading-5 font-medium text-gray-900">
                                    <img class="mx-auto" src="@/assets/inbuiltalerts-image6.png" alt="Bookmark Button">
                                </td>
                                <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-500">
                                    Running with LHS of Bound Constraint with Positive Factor
                                </td>
                                </tr>
                                <!-- Even row -->
                                <tr class="bg-white">
                                <td class="px-6 py-4 whitespace-wrap text-sm leading-5 font-medium text-gray-900">
                                    <img class="mx-auto" src="@/assets/inbuiltalerts-image7.png" alt="Capture Button">
                                </td>
                                <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-500">
                                    Running with LHS of Bound Constraint with Negative Factor.
                                </td>
                                </tr>
                                <!-- Odd row -->
                                <tr class="bg-white">
                                <td class="px-6 py-4 whitespace-wrap text-sm leading-5 font-medium text-gray-900">
                                    <img class="mx-auto" src="@/assets/inbuiltalerts-image8.png" alt="Configure Button">
                                </td>
                                <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-500">
                                    Not Running and LHS of Bound Constraint with Positive Factor
                                </td>
                                </tr>
                                <!-- Even row -->
                                <tr class="bg-white">
                                <td class="px-6 py-4 whitespace-wrap text-sm leading-5 font-medium text-gray-900">
                                    <img class="mx-auto" src="@/assets/inbuiltalerts-image9.png" alt="Help Button">
                                </td>
                                <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-500">
                                    Not Running and LHS of Bound Constraint with Positive Factor
                                </td>
                                </tr>
                                <!-- Odd row -->
                                <tr class="bg-white">
                                <td class="px-6 py-4 whitespace-wrap text-sm leading-5 font-medium text-gray-900">
                                    <img class="mx-auto" src="@/assets/inbuiltalerts-image10.png" alt="Feedback Button">
                                </td>
                                <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-500">
                                    Running with LHS of Bound Constraint with Positive and Negative Factors
                                </td>
                                </tr>
                                <!-- Even row -->
                                <tr class="bg-white">
                                <td class="px-6 py-4 whitespace-wrap text-sm leading-5 font-medium text-gray-900">
                                    <img class="mx-auto" src="@/assets/inbuiltalerts-image11.png" alt="Help Button">
                                </td>
                                <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-500">
                                    Not Running with LHS of Bound Constraint with Positive and Negative Factors
                                </td>
                                </tr>
                            </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <p>An example of where you will find these icons used is within the Regional Schematic widgets. </p>
            <br/>
            <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Icons used for bid changes</h3>
            <br/>
            <img class="mx-auto" src="@/assets/inbuiltalerts-image12.png" alt="Thermal Scale">
            <br/>
            <p>Where the bid for a unit has changed (e.g. a rebid might have taken effect) since the previous dispatch interval, we use the following icons:</p>
            <div class="mx-auto flex flex-col">
                <div class="mx-auto">
                    <div class="mx-auto px-8 py-8 align-middle inline-block sm:px-8 lg:px-8">
                        <div class="mx-auto shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                            <table class="mx-auto divide-y divide-gray-200">
                            <thead>
                                <tr>
                                <th class="px-2 py-2 bg-gray-100 leading-2 text-gray-500">
                                    <p class="text-lg text-center font-bold">Icon</p>
                                </th>
                                <th class="px-2 py-2 bg-gray-100 leading-4 text-gray-500">
                                    <p class="text-lg text-center font-bold">Meaning</p>
                                </th>
                                </tr>
                            </thead>
                            <tbody>
                                <!-- Odd row -->
                                <tr class="bg-white">
                                <td class="px-6 py-4 whitespace-wrap text-sm leading-5 font-medium text-gray-900">
                                    <img class="mx-auto" src="@/assets/inbuiltalerts-image13.png" alt="Bid Unchanged Icon">
                                </td>
                                <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-500">
                                    Bid has not changed from previous dispatch interval.
                                </td>
                                </tr>
                                <!-- Even row -->
                                <tr class="bg-white">
                                <td class="px-6 py-4 whitespace-wrap text-sm leading-5 font-medium text-gray-900">
                                    <img class="mx-auto" src="@/assets/inbuiltalerts-image14.png" alt="Bid Changed Icon">
                                </td>
                                <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-500">
                                    Bid has changed from previous dispatch interval.
                                </td>
                                </tr>
                            </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <p>When the bid has changed, and the icon appears, this represents either of two cases in the way that the bid has changed:</p>
            <ol class="pl-12 pt-2 pb-2 list-decimal">
                <li>There was a previously scheduled change in bid that took effect in this dispatch interval (such as in the first dispatch interval within a new trading period); or</li>
                <li>There was a rebid that took effect in this dispatch interval.</li>
            </ol>
            <br/>
            <p><b>Note:</b> As per market protocol, bid data is published at 04:00AM the following day. Hence, the bid change icon will not alert in real-time mode.</p>
            <br>
            <p>An example of where you will find rebid icons used include within the Regional Schematic widgets. </p>
            <br/>
            <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Icons used for price setter</h3>
            <br/>
            <img class="mx-auto" src="@/assets/inbuiltalerts-image15.png" alt="Price Setter in Widget">
            <br/>
            <img class="mx-auto" src="@/assets/inbuiltalerts-image16.png" alt="Price Setter for Regions in Widget">
            <br/>
            <p>Within our widgets that relate to bidding, an icon is used to represent when a unit’s bid contributed to setting the market price for energy or an ancillary service in a region. The number of icons displayed indicates the number of market region prices the band was a price setter for. These icons are used to represent price setters:</p>
            <div class="mx-auto flex flex-col">
                <div class="mx-auto">
                    <div class="mx-auto px-8 py-8 align-middle inline-block sm:px-8 lg:px-8">
                        <div class="mx-auto shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                            <table class="mx-auto divide-y divide-gray-200">
                            <thead>
                                <tr>
                                <th class="px-2 py-2 bg-gray-100 leading-2 text-gray-500">
                                    <p class="text-lg text-center font-bold">Icon</p>
                                </th>
                                <th class="px-2 py-2 bg-gray-100 leading-4 text-gray-500">
                                    <p class="text-lg text-center font-bold">Meaning</p>
                                </th>
                                </tr>
                            </thead>
                            <tbody>
                                <!-- Odd row -->
                                <tr class="bg-white">
                                <td class="px-6 py-4 whitespace-wrap text-sm leading-5 font-medium text-gray-900">
                                    <img class="w-1/1 mx-auto" src="@/assets/inbuiltalerts-image17.png" alt="Price Setter Big Icon" style="height:30px; width: 40px;">
                                </td>
                                <td class="px-6 py-4 text-sm leading-5 text-gray-500">
                                    Indicates that the band is a Marginal Price Band. This band was dispatched pro-rata of its band size relative to other marginal bands affecting this market region (potentially corrected for inter-regional losses).
                                </td>
                                </tr>
                                <!-- Even row -->
                                <tr class="bg-white">
                                <td class="px-6 py-4 whitespace-wrap text-sm leading-5 font-medium text-gray-900">
                                    <img class="mx-auto" src="@/assets/inbuiltalerts-image18.png" alt="Price Setter Small Icon" style="height:34px; width: 40px;">
                                </td>
                                <td class="px-6 py-4 text-sm leading-5 text-gray-500">
                                    Indicates that the band is a Non-Marginal Price-Tied Price Band. This energy band belongs to a unit that was inflexibly dispatched. It had the same price as other marginal energy bands, resulting in a tie-breaking cost penalty due to violation of the proportional dispatch sharing arrangement.
                                </td>
                                </tr>
                            </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <p>An example of where you will find these icons used is within the Current Bids widget.</p>
        </div>
    </div>
    </div>
</template>

<script>
export default {
    title: 'In-Built Alerts'
};
</script>