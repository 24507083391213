<template>
    <div class="relative py-16 bg-white overflow-hidden">
    <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
    </div>
    <div class="relative px-4 sm:px-6 lg:px-8">
        <div class="text-lg mx-auto mb-6">
            <router-link to="/widget-guides" class="block"><p class="text-base text-center leading-4 text-grblue font-semibold tracking-wide hover:underline">Widget Guides</p></router-link>
            <h1 class="mt-4 mb-10 text-4xl text-center leading-10 font-extrabold tracking-tight text-grblack sm:text-5xl sm:leading-10">Constraint Sets</h1>
            <p class="text-xl text-gray-500 leading-8 font-semibold">
                The Constraint Sets widget shows a Gantt Chart of all constraint sets that have been invoked or are scheduled to be invoked within a time range. 
                This widget is useful for you to have an overview of when constraint shave impacted the market, and to see upcoming scheduled constraints. </p>
                <div class="text-gray-500 mx-auto leading-7 font-normal text-lg">
                <br/>
                <img class="w-2/3 rounded-m mx-auto" src="@/assets/constraintsets-image1.png" alt="Constraint Sets Widget">
                <br/>
                <h2 class="mt-16 mb-4 text-4xl leading-12 font-bold tracking-tight text-grblack lg:leading-4">Description</h2>
                <p>The time range defaults to look back 7 days and look forward ninety days. 
                    This is configurable by clicking the cog icon in the top right hand corner of the widget and modifying the chart look back and look forward values. 
                </p>
                <br/>
                <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Gantt Chart</h3>
                <p>The Gantt chart displays a bar chart where each bar represents an individual constraint set plotted against the x-axis which represents the days for which the constraint set has or is scheduled to be invoked. The red vertical line represents the current dispatch interval.</p>
                <br/>
                <p>Click the cog icon in the top right hand corner of the widget to modify the chart look back and look forward. If the cog icon is not present this feature is not available in your current version of ez2view. </p>
                <br/>
                <p>Constraint Sets are grouped on the left hand side using their "Coverage" property. Each constraint set that has a "coverage" value corresponding to a region is grouped under that region, and all other sets are grouped under a heading of "Other". </p>
                <br/>
                <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Filters</h3>
                <br/>
                <img class="w-1/4 rounded-m mx-auto" src="@/assets/constraintsets-image2.png" alt="Constraint Sets Widget">
                <br/>
                <p>You can filter the constraint sets that appear on the Gantt Chart so that ez2view displays the sets that are most relevant to you. You can open the Filter window by clicking the 'Create' or 'Edit' button in the toolbar at the top of the widget. </p>
                <br/>
                <img class="w-1/2 rounded-m mx-auto" src="@/assets/constraintsets-image3.png" alt="Constraint Sets Widget">
                <br/>
                <p>When creating a filter, you can specify clauses that match data on the Constraint Set Id, Coverage, Description, Outage or Owner.</p>
            </div>
        </div>
    </div>
    </div>
</template>

<script>
export default {
    title: 'Constraint Sets'
};
</script>