<template>
<div class="relative py-16 bg-white overflow-hidden">
  <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
  </div>
  <div class="relative px-4 sm:px-6 lg:px-8">
    <div class="text-lg mx-auto mb-6">
      <h1 class="mt-6 mb-10 text-4xl text-center leading-10 font-extrabold tracking-tight text-grblack sm:text-5xl sm:leading-10">Complex Market Concepts</h1>
      <p class="text-xl text-gray-500 leading-8">In this section of the site we will provide a general overview of some of the more intricate market concepts and how you can use ez2view to analyse their related data sets. </p>
    </div>

    <ul class="grid grid-cols-1 gap-6 lg:grid-cols-2 xl:grid-cols-3">

      <li class="h-full col-span-1 bg-white rounded-lg shadow cursor-default">
        <div class="w-full flex items-center justify-between p-6 space-x-6">
          <div class="flex-1 ">
            <div class="flex items-center space-x-3">
              <h3 class="text-grblack xl:text-xl leading-6 font-bold hover:text-blue-600 hover-show lg:text-lg">Unit Dispatch and Conformance</h3>
              <h3 class="pl-4 text-gryellow text-xl leading-6 font-bold hover-hide">Coming Soon</h3>
            </div>
            <p class="mt-1 text-gray-500 text-sm leading-5">Analysing unit generation output, availability and other dispatch data.</p>
          </div>
        </div>
      </li>

      <li class="h-full col-span-1 bg-white rounded-lg shadow cursor-default">
        <div class="w-full flex items-center justify-between p-6 space-x-6">
          <div class="flex-1">
            <div class="flex items-center space-x-3 ">
              <h3 class="text-grblack xl:text-xl leading-6 font-bold hover:text-blue-600 hover-show lg:text-lg">Bidding and Price Setting</h3>
              <h3 class="pl-4 text-gryellow text-xl leading-6 font-bold hover-hide">Coming Soon</h3>
            </div>
            <p class="mt-1 text-gray-500 text-sm leading-5">Analysing unit bids, rebids, price setting, and other bidding data. </p>
          </div>
        </div>
      </li>

      <li class="h-full col-span-1 bg-white rounded-lg shadow cursor-default">
        <div class="w-full flex items-center justify-between p-6 space-x-6">
          <div class="flex-1">
            <div class="flex items-center space-x-3">
              <h3 class="text-grblack xl:text-xl leading-6 font-bold hover:text-blue-600 hover-show lg:text-lg">Regions and Interconnectors</h3>
              <h3 class="pl-4 text-gryellow text-xl leading-6 font-bold hover-hide">Coming Soon</h3>
            </div>
            <p class="mt-1 text-gray-500 text-sm leading-5">Analysing the regions of the NEM and the interconnectors that link them together.</p>
          </div>
        </div>
      </li>

      <li class="h-full col-span-1 bg-white rounded-lg shadow cursor-default">
        <div class="w-full flex items-center justify-between p-6 space-x-6">
          <div class="flex-1">
            <div class="flex items-center space-x-3">
              <h3 class="text-grblack xl:text-xl leading-6 font-bold hover:text-blue-600 hover-show lg:text-lg">FCAS and Co-optimisation</h3>
              <h3 class="pl-4 text-gryellow text-xl leading-6 font-bold hover-hide">Coming Soon</h3>
            </div>
            <p class="mt-1 text-gray-500 text-sm leading-5">Analysing the Frequency Control Ancillary Services market and related data. </p>
          </div>
        </div>
      </li>

      <li class="h-full col-span-1 bg-white rounded-lg shadow cursor-default">
        <div class="w-full flex items-center justify-between p-6 space-x-6">
          <div class="flex-1">
            <div class="flex items-center space-x-3">
              <h3 class="text-grblack xl:text-xl leading-6 font-bold hover:text-blue-600 hover-show lg:text-lg">Constraints</h3>
              <h3 class="pl-4 text-gryellow text-xl leading-6 font-bold hover-hide">Coming Soon</h3>
            </div>
            <p class="mt-1 text-gray-500 text-sm leading-5">Analysing physical network restrictions and formulated constraint equations. </p>
          </div>
        </div>
      </li>

      <li class="h-full col-span-1 bg-white rounded-lg shadow cursor-default">
        <div class="w-full flex items-center justify-between p-6 space-x-6">
          <div class="flex-1">
            <div class="flex items-center space-x-3">
              <h3 class="text-grblack xl:text-xl leading-6 font-bold hover:text-blue-600 hover-show lg:text-lg">Forecasting</h3>
              <h3 class="pl-4 text-gryellow text-xl leading-6 font-bold hover-hide">Coming Soon</h3>
            </div>
            <p class="mt-1 text-gray-500 text-sm leading-5">Analysing forecast data and forecast convergence for price, generation, etc.</p>
          </div>
        </div>
      </li>

    </ul>
    
  </div>
</div>
</template>

<script>
export default {
    title: 'Complex Market Concepts'
};
</script>