<template>
    <div class="relative py-16 bg-white overflow-hidden">
    <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
    </div>
    <div class="relative px-4 sm:px-6 lg:px-8">
        <div class="text-lg mx-auto mb-6">
            <router-link to="/widget-guides" class="block"><p class="text-base text-center leading-4 text-grblue font-semibold tracking-wide hover:underline">Widget Guides</p></router-link>
            <h1 class="mt-4 mb-10 text-4xl text-center leading-10 font-extrabold tracking-tight text-grblack sm:text-5xl sm:leading-10">Dispatch Constraints</h1>
            <p class="text-xl text-gray-500 leading-8 font-semibold">The Dispatch Constraints widget shows all constraint equations that are invoked in the current dispatch interval. This widget is useful to help identify when and which constraints are affecting dispatch, and hence price.</p>
            <div class="text-gray-500 mx-auto leading-7 font-normal text-lg">
                <br/>
                <img class="w-3/4 rounded-m mx-auto" src="@/assets/dispatchconstraints-image1.png" alt="Gen Change Widget">
                <br/>
                <h2 class="mt-16 mb-4 text-4xl leading-12 font-bold tracking-tight text-grblack lg:leading-4">Description</h2>
                <p>The widget displays a table containing all constraint equations that are currently invoked. Each Constraint Equation is represented by a row that shows the Description, Marginal Value (MV), Left Hand Side (LHS), Right Hand Side (RHS), Headroom and Violation Degree (VD) for the equation. </p>
                <br/>
                <p>Additionally, the top toolbar allows you to filter the constraints that are displayed in the table, and allows you to export the data as a CSV file.</p>
                <h2 class="mt-16 mb-4 text-4xl leading-12 font-bold tracking-tight text-grblack lg:leading-4">Toolbar</h2>
                <p>The top toolbar gives you the ability to filter constraints that are displayed in the table below. You can also export the data as a CSV file </p>
                <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Filters</h3>
                <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Quick Filters</h3>
                <p>The Quick Filter allows you to change the data displayed in the table below through the use of radio buttons and tick boxes. You can choose to show only binding constraints, remove 'NIL' and/or remove 'FCAS' constraints.  </p>
                <br/>
                <img class="w-2/5 rounded-m mx-auto" src="@/assets/dispatchconstraints-image2.png" alt="Dispatch Constraints - Quick Filter">
                <br/>
                <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Power Filters</h3>
                <p>Through a Power Filter, you can create a customised filter to select specific criteria of the constraints that displayed. You can filter using criteria such as Constraint ID,  Constraint Weight, Limit Type, etc. </p>
                <br/>
                <img class="w-1/4 rounded-m mx-auto" src="@/assets/currentbids-image3.png" alt="Toolbar Power Filter">
                <br/>
                <h2 class="mt-16 mb-4 text-4xl leading-12 font-bold tracking-tight text-grblack lg:leading-4">Table</h2>
                <p>Each row of the table represents a contraint equation that is invoked for the dispatch interval. The columns of the table represent the following fields:</p>
                <div class="mx-auto flex flex-col">
                <div class="mx-auto">
                    <div class="mx-auto px-8 py-8 align-middle inline-block sm:px-8 lg:px-8">
                        <div class="mx-auto shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                            <table class="mx-auto divide-y divide-gray-200">
                                <thead>
                                    <tr>
                                    <th class="px-2 py-2 bg-gray-100 leading-2 text-grblack">
                                        <p class="text-lg text-center font-bold">Field</p>
                                    </th>
                                    <th class="px-2 py-2 bg-gray-100 leading-4 text-grblack">
                                        <p class="text-lg text-center font-bold">Description</p>
                                    </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="bg-white">
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                                        ID
                                    </td>
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                        The ID for the Constraint Equation
                                    </td>
                                    </tr>
                                    <tr class="bg-white">
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                                        Description
                                    </td>
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                        A brief description of the equation as submitted by the AEMO
                                    </td>
                                    </tr>
                                    <tr class="bg-white">
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                                        MV
                                    </td>
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                        Marginal Value for this Dispatch Interval 
                                    </td>
                                    </tr>
                                    <tr class="bg-white">
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                                        LHS
                                    </td>
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                        Left Hand Side of the Constraint Equation
                                    </td>
                                    </tr>
                                    <tr class="bg-white">
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                                        RHS
                                    </td>
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                        Right Hand Side of the Constraint Equation
                                    </td>
                                    </tr>
                                    <tr class="bg-white">
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                                        Headroom
                                    </td>
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                        Difference between the LHS and RHS of the Constraint Equation 
                                    </td>
                                    </tr>
                                    <tr class="bg-white">
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                                        VD
                                    </td>
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                        Violation Degree 
                                    </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                </div>
                <br/>
                <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Ordering and highlighting</h3>
                <p>In the table, constraint equations are ordered by marginal value (highgest to lowest) and then constraint ID (alphabetically).</p>
                <br/>
                <p>Bound constraints (i.e. where the LHS=RHS and the marginal value is non-zero) are highlighted in yellow. Penalty constraints (i.e. constraints whose marginal value is > 10 or &lt; -10) are highlighted in red.</p>
            </div>
        </div>
    </div>
    </div>
</template>

<script>
export default {
    title: 'Dispatch Contraints'
};
</script>