<template>
    <div class="relative py-16 bg-white overflow-hidden">
    <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
    </div>
    <div class="relative px-4 sm:px-6 lg:px-8">
        <div class="text-lg mx-auto mb-6">
            <router-link to="/widget-guides" class="block"><p class="text-base text-center leading-4 text-grblue font-semibold tracking-wide hover:underline">Widget Guides</p></router-link>
            <h1 class="mt-4 mb-10 text-4xl text-center leading-10 font-extrabold tracking-tight text-grblack sm:text-5xl sm:leading-10">Bid Explorer</h1>
            <p class="text-xl text-gray-500 leading-8 font-semibold">The Bid Explorer widget allows you to filter a list of bids and view the details behind each one, in order for you to explore and understand how and if a bid has evolved over time. The widget is especially useful to see the differences and impact between each rebid.</p>
            <div class="text-gray-500 mx-auto leading-7 font-normal text-lg">
                <br/>
                <img class="w-3/4 rounded-m mx-auto" src="@/assets/bidexplorer-image1.png" alt="Generator Outages Widget">
                <br/>
                <h2 class="mt-16 mb-4 text-4xl leading-12 font-bold tracking-tight text-grblack lg:leading-4">Description</h2>
                <p>The Bid Explorer widget contains a toolbar, table and chart relating to generator bids for a given commodity and period of time. The table contains all historical bids or rebids that relate to the configuration of the toolbar and filters.</p>
                <br/>
                <p>When a bid is selected in the table, the chart underneath will show a chart with the x-axis being the given trading day - this chart displays the volumes bid in each band as it related to the specific bid.  </p>
                <br/>
                <img class="w-2/3 rounded-m mx-auto" src="@/assets/bidexplorer-image2.png" alt="Generator Outages Widget showing Tooltip">
                <br/>
                <h2 class="mt-16 mb-4 text-4xl leading-12 font-bold tracking-tight text-grblack lg:leading-4">Toolbar</h2>
                <p>The toolbar at the top of the widget lets you configure which commodity type the data is being shown for, and the span of time (look-back and look-forward period) shown in the charts. </p>
                <br/>
                <img class="w-1/2 rounded-m mx-auto" src="@/assets/bidexplorer-image3.png" alt="Bid Explorer Widget Components">
                <br/>
                <p>You can filter the data displayed in the bids table underneath by using one of the two filters described below.</p>
                <filters-quick/>
                <filters-power/>
                <br/>
                <h2 class="mt-16 mb-4 text-4xl leading-12 font-bold tracking-tight text-grblack lg:leading-4">Bids Table</h2>
                <br/>
                <p>Each row of this table represents an individual bid with the columns representing the data relating to that bid (e.g. DUID, trading day, offer date, explanation, etc.). The bids displayed are filtered based on how you have configured the toolbar and filters as explained above. Rows that are greyed out within this table represents bids that were never dispatched (i.e. were superseded by a later rebid).</p>
                <br/>
                <img class="w-3/4 rounded-m mx-auto" src="@/assets/bidexplorer-image4.png" alt="Bid Explorer Widget Bids Table">
                <br/>
                <p>By scrolling right along the table rows, you will see more columns. For some column headers, you have the option to toggle on/off some of the bid types within your table like shown in the image below. </p>
                <br/>
                <img class="w-1/4 rounded-m mx-auto" src="@/assets/bidexplorer-image6.png" alt="Bid Explorer Widget Bids Table">
                <br/>
                <p>There are three states for each of these toggles, and the table below explains their meaning. </p>
                <div class="mx-auto flex flex-col">
                <div class="mx-auto">
                    <div class="mx-auto px-8 py-8 align-middle inline-block sm:px-8 lg:px-8">
                        <div class="mx-auto shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                            <table class="mx-auto divide-y divide-gray-200">
                                <thead>
                                    <tr>
                                    <th class="px-2 py-2 bg-gray-100 leading-2 text-grblack">
                                        <p class="text-lg text-center font-bold">Toggle</p>
                                    </th>
                                    <th class="px-2 py-2 bg-gray-100 leading-4 text-grblack">
                                        <p class="text-lg text-center font-bold">Meaning</p>
                                    </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <!-- Odd row -->
                                    <tr class="bg-white">
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                                        <img class="mx-auto" src="@/assets/bidexplorer-image7.png" alt="Inspect Button">
                                    </td>
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                        Show Only False (e.g. only show bids that were not dispatched).
                                    </td>
                                    </tr>
                                    <!-- Odd row -->
                                    <tr class="bg-white">
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                                        <img class="mx-auto" src="@/assets/bidexplorer-image8.png" alt="Compare Button">
                                    </td>
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                        Show Only True (e.g. only show bids that were dispatched).
                                    </td>
                                    </tr>
                                    <!-- Odd row -->
                                    <tr class="bg-white">
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                                        <img class="mx-auto" src="@/assets/bidexplorer-image9.png" alt="Export Button">
                                    </td>
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                        Show Both (e.g. show all bids, dispatched and not dispatched).
                                    </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                </div>
                <p></p>
                <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Inspect Buttons</h3>
                <p>In the top right hand corner of the table, you will be able to further inspect bids using the following buttons:</p>
                <div class="mx-auto flex flex-col">
                <div class="mx-auto">
                    <div class="mx-auto px-8 py-8 align-middle inline-block sm:px-8 lg:px-8">
                        <div class="mx-auto shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                            <table class="mx-auto divide-y divide-gray-200">
                                <thead>
                                    <tr>
                                    <th class="px-2 py-2 bg-gray-100 leading-2 text-grblack">
                                        <p class="text-lg text-center font-bold">Button</p>
                                    </th>
                                    <th class="px-2 py-2 bg-gray-100 leading-4 text-grblack">
                                        <p class="text-lg text-center font-bold">Meaning</p>
                                    </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <!-- Odd row -->
                                    <tr class="bg-white">
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                                        <img class="mx-auto" src="@/assets/bidsandoffers-image10.png" alt="Inspect Button">
                                    </td>
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                        Click this button to open the Bid Details widget for the bid/offer currently selected. 
                                    </td>
                                    </tr>
                                    <!-- Odd row -->
                                    <tr class="bg-white">
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                                        <img class="mx-auto" src="@/assets/bidsandoffers-image11.png" alt="Compare Button">
                                    </td>
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                        Select two bids/offers (using Ctrl+Click to select the second one) and then click this button to open the Bid Details widget in <i>comparison mode</i> which shows a visual comparison.
                                    </td>
                                    </tr>
                                    <!-- Odd row -->
                                    <tr class="bg-white">
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                                        <img class="mx-auto" src="@/assets/bidsandoffers-image12.png" alt="Export Button">
                                    </td>
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                        Click this button to export the data as a CSV file.
                                    </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                </div>
                <h2 class="mt-16 mb-4 text-4xl leading-12 font-bold tracking-tight text-grblack lg:leading-4">Bid Chart</h2>
                <br/>
                <img class="w-4/5 rounded-m mx-auto" src="@/assets/bidexplorer-image5.png" alt="Bid Explorer Widget Bid Chart">
                <br/>
                <p>The graph in the widget is a stacked step area chart for the trading day (i.e. 4am to 4am) that the bid applies to or within. </p> 
                <br/> 
                <p>The dark checkered box that overlays the chart represents the period of time within the trading day that had already passed at the offer date and time (hence the period of time that the rebid does not, and can not, apply to). </p>
                <br/>
                <p><b>Tip:</b> After selecting a bid in the table, you can use the up and down arrows on your keyboard to control the chart and get a better visual representation of how/if the bid has evolved at each rebid.</p>
            </div>
        </div>
    </div>
    </div>
</template>

<script>
import FiltersQuick from './FiltersQuick.vue';
import FiltersPower from './FiltersPower.vue';

export default {
    components: { FiltersQuick, FiltersPower},
    title: 'Bid Explorer'
};
</script>