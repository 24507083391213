<template>
    <div class="relative py-16 bg-white overflow-hidden">
    <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
    </div>
    <div class="relative px-4 sm:px-6 lg:px-8">
        <div class="text-lg mb-2 mx-auto">
            <router-link to="/design-principles" class="block"><p class="text-base text-center leading-4 text-grblue font-semibold tracking-wide hover:underline">Design Principles</p></router-link>
        </div>
        <nav class="border-t border-gray-200 px-4 flex items-center justify-between sm:px-0">
            <div class="-mt-px w-0 flex-1 flex">
                <router-link to="/design-principles/inbuiltalerts" class="border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-md font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300">
                    <!-- Heroicon name: arrow-narrow-left -->
                    <svg class="mr-3 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z" clip-rule="evenodd" />
                    </svg>
                    <p>Previous: <b> Alerting Colours and Icons</b></p>
                </router-link>
            </div>
            <div class="hidden md:-mt-px md:flex">
            </div>
            <div class="-mt-px w-0 flex-1 flex justify-end">
                <router-link to="/design-principles/quickaccessbuttons" class="border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-md font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300">
                    <p>Next: <b> Quick Access Buttons</b></p>
                    <!-- Heroicon name: arrow-narrow-right -->
                    <svg class="ml-3 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd" />
                    </svg>
                </router-link>
            </div>
        </nav>
        <div class="text-lg mx-auto mb-6">
            <h1 class="mt-6 mb-10 text-4xl text-center leading-10 font-extrabold tracking-tight text-grblack sm:text-5xl sm:leading-10">Customising your own alerts</h1>
            <p class="text-xl text-gray-500 leading-8 font-semibold">You can create custom alerts in order for you to be informed in advance, or during market events, that affect your organisation.</p>
        </div>
        <div class="text-gray-500 mx-auto leading-7 font-normal text-lg">
            <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">On-screen alerts</h3>
            <br/>
            <img class="w-full lg:w-1/2 rounded-m mx-auto" src="@/assets/customalerts-image1.png" alt="On-screen alerts">
            <br/>
            <p>Through the Notifications widget you can configure rules that trigger on-screen alerts when a value within a data set goes above or below a certain threshold e.g. “QLD Dispatch Price > $100.00”. These alerts will then appear in the widget itself each time it is triggered. </p>
            <br/>
            <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Email alerts</h3>
            <br/>
            <img class="w-full lg:w-1/2 rounded-m mx-auto" src="@/assets/customalerts-image2.png" alt="Email alerts">
            <br/>
            <p>The Notifications widget also gives you the ability to have alerts sent directly to your inbox. You configure these in the same way as on-screen alerts, as there is an additional option that allows you to enter an email address to send the alerts to. </p>
            <br/>
            <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Audio alerts</h3>
            <p>You can also set up audio alerts that play a sound, or spoken message when an alert is triggered. These can also be configured via the Notifications widget.</p>
            <br/>
            <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">SMS alerts</h3>
            <br/>
            <img class="w-full lg:w-1/3 rounded-m mx-auto" src="@/assets/customalerts-image3.png" alt="Email alerts">
            <br/>
            <p>The SMS Alarms widget allows can configure rules and set up alerts that are sent directly to your phone.</p>
        </div>
    </div>
    </div>
</template>

<script>
export default {
    title: 'Custom Alerts'
};
</script>