<template>
    <div class="relative py-16 bg-white overflow-hidden">
    <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
    </div>
    <div class="relative px-4 sm:px-6 lg:px-8">
        <div class="text-lg mx-auto mb-6">
            <router-link to="/widget-guides" class="block"><p class="text-base text-center leading-4 text-grblue font-semibold tracking-wide hover:underline">Widget Guides</p></router-link>
            <h1 class="mt-4 mb-10 text-4xl text-center leading-10 font-extrabold tracking-tight text-grblack sm:text-5xl sm:leading-10">Bound Constraints</h1>
            <p class="text-xl text-gray-500 leading-8 font-semibold">The Bound Constraint widget shows all the constraints that are bound in the current dispatch interval. Generally, only a small number of constraints are bound in any given dispatch interval, hence this widget is useful in identifying all bound constraints.</p>
            <div class="text-gray-500 mx-auto leading-7 font-normal text-lg">
                <br/>
                <img class="w-2/3 rounded-m mx-auto" src="@/assets/boundconstraints-image1.png" alt="Bound Constraints Widget">
                <br/>
                <h2 class="mt-16 mb-4 text-4xl leading-12 font-bold tracking-tight text-grblack lg:leading-4">Description</h2>
                <p>The Bound Constraint Summary is a hierarchical display that groups constraints according to: </p>
                <ol class="pl-8 pt-2 pb-2">
                    <li><b>1.</b> Coverage/Region of the Constraint Set</li>
                    <li><b>2.</b> Constraint Set</li>
                    <li><b>3.</b> Constraint Equation</li>
                    <li><b>4.</b> Left Hand Side Terms</li>
                </ol>
                <br/>
                <p>It's important to note that many constraint equations may be invoked at a given time, but only a small number are typically binding in a given dispatch interval. </p>
                <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Expanding each constraint</h3>
                <p>You can expand each group element in the hierarchy by clicking on the triangle that appears on the left of the element. </p>
                <br/>
                <img class="w-2/3 rounded-m mx-auto" src="@/assets/boundconstraints-image2.png" alt="Bound Constraints Widget - Explanded">
                <br/>
                <p>The number on the right of the constraint equation shows the Marginal Value for the equation.</p>
                <br/>
                <img class="w-1/7 rounded-m mx-auto" src="@/assets/boundconstraints-image3.png" alt="Bound Constraints Widget - Marginal Value">
                <br/>
                <p>The number displayed to the left of each Left Hand Side term is the factor (multiplier) for that term in the equation. </p>
                <br/>
                <img class="w-1/7 rounded-m mx-auto" src="@/assets/boundconstraints-image4.png" alt="Bound Constraints Widget - Left Hand Side Multiplier">
                <br/>
                <p> Further information on constraints is available in the Constraints page of our 
                <router-link to="/complex-market-concepts" class="inline text-blue-600 hover:underline">Complex Market Concepts</router-link>
                   section of this site.
                </p>
            </div>
        </div>
    </div>
    </div>
</template>

<script>
export default {
    title: 'Bound Contraints'
};
</script>