<template>
    <div class="relative py-16 bg-white overflow-hidden">
    <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
    </div>
    <div class="relative px-4 sm:px-6 lg:px-8">
        <div class="text-lg mb-2 mx-auto">
            <router-link to="/design-principles" class="block"><p class="text-base text-center leading-4 text-grblue font-semibold tracking-wide hover:underline">Design Principles</p></router-link>
        </div>
        <nav class="border-t border-gray-200 px-4 flex items-center justify-between sm:px-0">
            <div class="-mt-px w-0 flex-1 flex">
                <router-link to="/design-principles/customalerts" class="border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-md font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300">
                    <!-- Heroicon name: arrow-narrow-left -->
                    <svg class="mr-3 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z" clip-rule="evenodd" />
                    </svg>
                    <p>Previous: <b> Customising Alerts</b></p>
                </router-link>
            </div>
            <div class="hidden md:-mt-px md:flex">
            </div>
            <div class="-mt-px w-0 flex-1 flex justify-end">
                <router-link to="/design-principles/sharingpreferences" class="border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-md font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300">
                    <p>Next: <b> Sharing Preferences</b></p>
                    <!-- Heroicon name: arrow-narrow-right -->
                    <svg class="ml-3 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd" />
                    </svg>
                </router-link>
            </div>
        </nav>
        <div class="text-lg mx-auto mb-6">
            <h1 class="mt-6 mb-10 text-4xl text-center leading-10 font-extrabold tracking-tight text-grblack sm:text-5xl sm:leading-10">Quick Access Buttons</h1>
            <p class="text-xl text-gray-500 leading-8 font-semibold">There are common buttons and other input fields that exist across different widgets in ez2view. They help you perform small tasks or assist with navigation through the interface.</p>
        </div>
        <div class="text-gray-500 mx-auto leading-7 font-normal text-lg">
            <br/>
            <img class="w-full lg:w-1/6 rounded-m mx-auto" src="@/assets/quickaccessbuttons-image1.png" alt="Quick Access Buttons">
            <br/>
            <p>In the top right-hand corner of every widget you will find a set of buttons, like shown above, that relate to the widget itself. The table below describes the meaning of some of the buttons that you will find within ez2view.</p>
        </div>
        <div class="flex flex-col">
            <div class="-my-2 sm:-mx-6 lg:-mx-8">
                <div class="px-8 py-8 align-middle inline-block sm:px-8 lg:px-8">
                <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                    <table class="min-w-full divide-y divide-gray-200">
                    <thead>
                        <tr>
                        <th class="px-2 py-2 bg-gray-100 leading-2 text-gray-500">
                            <p class="text-lg text-center font-bold">Button</p>
                        </th>
                        <th class="px-2 py-2 bg-gray-100 leading-4 text-gray-500">
                            <p class="text-lg text-center font-bold">Meaning</p>
                        </th>
                        </tr>
                    </thead>
                    <tbody>
                        <!-- Odd row -->
                        <tr class="bg-white">
                        <td class="px-6 py-4 text-sm leading-5 font-medium text-gray-900">
                            <img class="mx-auto" src="@/assets/quickaccessbuttons-image2.png" alt="Bookmark Button">
                        </td>
                        <td class="px-6 py-4 text-sm leading-5 text-gray-500">
                            <b>Bookmark</b> - clicking this button means the widget will now appear in the ‘Bookmarks’ folder within the ez2view explorer.
                        </td>
                        </tr>
                        <!-- Even row -->
                        <tr class="bg-white">
                        <td class="px-6 py-4  text-sm leading-5 font-medium text-gray-900">
                            <img class="mx-auto" src="@/assets/quickaccessbuttons-image3.png" alt="Capture Button">
                        </td>
                        <td class="px-6 py-4 text-sm leading-5 text-gray-500">
                            <b>Capture</b> - will copy an image of the current widget onto your clipboard.
                        </td>
                        </tr>
                        <!-- Odd row -->
                        <tr class="bg-white">
                        <td class="px-6 py-4 text-sm leading-5 font-medium text-gray-900">
                            <img class="mx-auto" src="@/assets/quickaccessbuttons-image4.png" alt="Configure Button">
                        </td>
                        <td class="px-6 py-4 text-sm leading-5 text-gray-500">
                            <b>Configure</b> - some widgets allow you to configure settings of how the widget works or how it is displayed.
                        </td>
                        </tr>
                        <!-- Even row -->
                        <tr class="bg-white">
                        <td class="px-6 py-4 text-sm leading-5 font-medium text-gray-900">
                            <img class="mx-auto" src="@/assets/quickaccessbuttons-image5.png" alt="Help Button">
                        </td>
                        <td class="px-6 py-4 text-sm leading-5 text-gray-500">
                            <b>Get Help</b> - clicking on this button will take you to the detailed online help documentation page for this widget, which will include instructions on how the widget works.
                        </td>
                        </tr>
                        <!-- Odd row -->
                        <tr class="bg-white">
                        <td class="px-6 py-4 text-sm leading-5 font-medium text-gray-900">
                            <img class="mx-auto" src="@/assets/quickaccessbuttons-image6.png" alt="Feedback Button">
                        </td>
                        <td class="px-6 py-4 text-sm leading-5 text-gray-500">
                            <b>Submit Feedback</b> - a window will appear that allows you to submit feedback to our development team.
                        </td>
                        </tr>
                        <!-- Even row -->
                        <tr class="bg-white">
                        <td class="px-6 py-4 text-sm leading-5 font-medium text-gray-900">
                            <img class="mx-auto" src="@/assets/quickaccessbuttons-image7.png" alt="Help Button">
                        </td>
                        <td class="px-6 py-4 text-sm leading-5 text-gray-500">
                            <b>Export as CSV</b> - will download the data shown within the widget (including filtered data) as a CSV file.
                        </td>
                        </tr>
                    </tbody>
                    </table>
                </div>
                </div>
            </div>
        </div>
        <div class="text-gray-500 mx-auto leading-7 font-normal text-lg">    
            <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Search within a widget</h3>
            <br/>
            <img class="rounded-m mx-auto" src="@/assets/quickaccessbuttons-image7.gif" style="height: 315px; width: 420px;  -webkit-font-smoothing: antialised; backface-visibility: hidden; transform: translateZ(0); -webkit-font-smoothing: subpixel-antialiased;" alt="Search">
            <br/>
            <p>In some widgets we have also included search functionality to enable you to shift the context of the widget. As shown above, clicking on the search button then allows you to scroll through all possible entries, or input text, to navigate through possible entries.</p>
        </div>
    </div>
    </div>
</template>

<script>
export default {
    title: 'Quick Access Buttons'
};
</script>