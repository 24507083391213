<template>
    <div class="relative py-16 bg-white overflow-hidden">
    <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
    </div>
    <div class="relative px-4 sm:px-6 lg:px-8">
        <div class="text-lg mb-2 mx-auto">
            <router-link to="/design-principles" class="block"><p class="text-base text-center leading-4 text-grblue font-semibold tracking-wide hover:underline">Design Principles</p></router-link>
        </div>
        <nav class="border-t border-gray-200 px-4 flex items-center justify-between sm:px-0">
            <div class="-mt-px w-0 flex-1 flex">
            </div>
            <div class="hidden md:-mt-px md:flex">
            </div>
            <div class="-mt-px w-0 flex-1 flex justify-end">
                <router-link to="/design-principles/pointintime" class="border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm sm:text-md font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300">
                <p>Next: <b> Point in Time View</b></p>
                <!-- Heroicon name: arrow-narrow-right -->
                <svg class="ml-3 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd" />
                </svg>
                </router-link>
            </div>
        </nav>
        <div class="text-lg mx-auto mb-6">
            <h1 class="mt-6 mb-10 text-4xl text-center leading-10 font-extrabold tracking-tight text-grblack sm:text-5xl sm:leading-10">Build Your Own Dashboard</h1>
            <p class="text-xl text-gray-500 leading-8 font-semibold">ez2view is highly configurable, allowing you to build your own dashboard so that you can maintain constant awareness over the areas of the market that affect your organisation.</p>
        </div>
        <div class="text-gray-500 mx-auto leading-7 font-normal text-lg">
            <img class="w-full lg:w-4/5 rounded-m mx-auto" src="@/assets/builddashboard-image1.png" alt="Build Your Own Dashboard">
            <br/>
            <br/>
            <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Widgets, Windows and Layouts</h3>
            <p>For the purposes of explanation, the image above and below show one of our default layouts within the installed software. In the image we have labelled the three layers of ez2view – the widgets, the windows and the overall layout.</p>
            <img class="w-full lg:w-3/4 mx-auto" src="@/assets/builddashboard-image2.png" alt="Widgets, Windows and Layouts">
            <p>A <b>layout</b> can contain multiple <b>windows</b>, and each window can contain multiple different <b>widgets</b>. All three of these layers are configurable to meet your specific needs. Below we explain each layer in more detail. </p>
            <h2 class="mt-16 mb-4 text-4xl leading-12 font-bold tracking-tight text-grblack lg:leading-4">Widgets</h2>
            <p>We use the term “widget” to describe an individual tool or display element that you can launch within the app. </p>
            <img class="w-full lg:w-3/4 mx-auto" src="@/assets/builddashboard-image3.png" alt="Widgets">
            <p>Each widget gives you insight into a particular aspect of the NEM - for example, the ‘Bids & Offers’ widget focuses on generator bidding. This widget in particular visualises each generator’s bid price, and lets you filter and analyse other bid data such as bid volume, price setter details, submitted rebid reason, etc. for a given dispatch interval.</p>
            <br/>
            <p>Within ez2view there are 50+ different widgets with each one focused on a different area of the market. Our development team continually builds new widgets that we release on a regular ongoing basis, but you can also configure your own time series widgets that chart your specified data sets by using the Trend Editor widget. </p>
            <br/>
            <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Widget Sets</h3>
            <p>If it’s not obvious already, you can view multiple widgets on your monitor(s) at once. Each widget is opened within a frame (also known as a ‘widget set’), and you can drag and drop widgets across frames or into a new window. In this regard, the interface is very similar to what you would be used to using in a standard web browser where you can drag, drop and rearrange tabs at the top of the browser.</p>
            <br/>
            <img class="mx-auto" src="@/assets/builddashboard-image4.gif" alt="Widget Sets" style="height: 120px; width: 610px; -webkit-font-smoothing: antialised; backface-visibility: hidden; transform: translateZ(0); -webkit-font-smoothing: subpixel-antialiased;">
            <br/>
            <br/>
            <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Docking</h3>
            <p>Clicking and holding the header of the widget allows you to drag the widget and drop it into a frame. As you do this, a <b>5-option control</b> (as shown in the image below) appears to help you configure the position of the widget on your screen.</p>
            <img class="w-2/5 lg:w-1/6 mx-auto" src="@/assets/builddashboard-image5.png" alt="5-Option Control">
            <p>To rearrange the position of a widget, drag the header over the control and then drop it over any of the outside four icons to split the frame as shown in the animated image below. Dropping the header over the icon in the center will open the widget within the selected frame.</p>
            <br/>
            <img class="mx-auto h-gif2" src="@/assets/builddashboard-image6.gif" alt="Repositioning Widgets" style="height: 600px; width: 440px; -webkit-font-smoothing: antialised; backface-visibility: hidden; transform: translateZ(0); -webkit-font-smoothing: subpixel-antialiased;">
            <h2 class="mt-16 mb-4 text-4xl leading-12 font-bold tracking-tight text-grblack lg:leading-4">Windows</h2>
            <p>You can split ez2view over multiple <b>windows</b> on your operating system. This allows you to use the software over two or more monitors and increases the number of widgets that you can view at once. </p>
            <img class="w-full lg:w-3/4 mx-auto" src="@/assets/builddashboard-image7.png" alt="Windows">
            <p>As we explain in <router-link to="/design-principles/pointintime" class="text-blue-600 hover:underline">the point-in-time view</router-link> section of this website, ez2view allows you to navigate through historical data through the time travel feature. The time-travel feature applies to each window, meaning that you can inspect different dispatch intervals in different windows, allowing for easier and more seamless forensic analysis when you want to compare two or more separate dispatch intervals.</p>
            <h2 class="mt-16 mb-4 text-4xl leading-12 font-bold tracking-tight text-grblack lg:leading-4">Layouts</h2>
            <p>Collectively, the display of all widgets in all windows is referred to as a layout.</p>
            <img class="w-full lg:w-3/4 mx-auto" src="@/assets/builddashboard-image8.png" alt="Layout">
            <p>When you have configured your widgets and windows the way you want, you can save the layout for future reference under the layout section in the top menu bar, and these saved layouts are accessible through the ez2view explorer.</p>
            <br/>
            <p>A small number of default ‘starter’ layouts are also available through the explorer.</p>
            <br/>
            <p>Once saved, you have the option of sharing this layout with others. How you do this depends on whether you have the “Shared Settings” database configured for your network, or if you’ve defaulted to using your local file system. </p>
            <br/>
        </div>
    </div>
    </div>
</template>


<script>
export default {
    title: 'Build Your Own Dashboard'
};
</script>

<style scoped>
    .h-gif2 {
        height: 600px;
    }

    .text-grblack {
        color: #403b41;
    }
</style>