<template>
    <div class="relative py-16 bg-white overflow-hidden">
        <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
        </div>
        <div class="relative px-4 sm:px-6 lg:px-8">
            <div class="text-lg mb-2 mx-auto">
                <router-link to="/design-principles" class="block"><p class="text-base text-center leading-4 text-grblue font-semibold tracking-wide hover:underline">Design Principles</p></router-link>
            </div>
            <nav class="border-t border-gray-200 px-4 flex items-center justify-between sm:px-0">
                <div class="-mt-px w-0 flex-1 flex">
                    <router-link to="/design-principles/pointintime" class="border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-md font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300">
                        <!-- Heroicon name: arrow-narrow-left -->
                        <svg class="mr-3 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd" d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z" clip-rule="evenodd" />
                        </svg>
                        <p>Previous: <b> Point in Time View</b></p>
                    </router-link>
                </div>
                <div class="hidden md:-mt-px md:flex">
                </div>
                <div class="-mt-px w-0 flex-1 flex justify-end">
                    <router-link to="/design-principles/ez2viewexplorer" class="border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-md font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300">
                        <p>Next: <b> ez2view Explorer</b></p>
                        <!-- Heroicon name: arrow-narrow-right -->
                        <svg class="ml-3 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd" />
                        </svg>
                    </router-link>
                </div>
            </nav> 
            <div class="text-lg mx-auto mb-6">
                <h1 class="mt-6 mb-10 text-4xl text-center leading-10 font-extrabold tracking-tight text-grblack sm:text-5xl sm:leading-10">Context-sensitive Hyperlinks</h1>
                <p class="text-xl text-gray-500 leading-8 font-semibold">Built-in hyperlinks are present throughout the software, providing you the ability to ‘drill in’ and progressively gain more detail and context. </p>
            </div>
            <div class="text-gray-500 mx-auto leading-7 font-normal text-lg">
                <p>Very similar to how hyperlinks work within a typical web browser, you can click on an underlined link to open a related widget that displays further details and insight.</p>
                <br/>
                <img class="w-full lg:w-1/3 rounded-m mx-auto" src="@/assets/hyperlinks-image1.png" alt="Hyperlinks in ez2view">
                <br/>
                <p>As an example of how these links work, the image above is a screenshot from a section of the QLD Schematic widget. Clicking on the link contained in the interconnector label “NSW1-QLD1”, will then open up the Interconnector Details widget which will then show recent forecast flows and limits for this specific interconnector.</p>
                <br/>
                <p>We’ve designed ez2view in this way so that you can navigate through information quicker and find related data easier.</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    title: 'Context-sensitive Linkages'
};
</script>
