<template>
  <div class="relative py-16 bg-white overflow-hidden">
    <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full"></div>
    <div class="relative px-4 sm:px-6 lg:px-8">
      <div class="text-lg mx-auto mb-6">
        <router-link to="/widget-guides" class="block"><p class="text-base text-center leading-4 text-grblue font-semibold tracking-wide hover:underline">Widget Guides</p></router-link>
        <h1 class="mt-4 mb-10 text-4xl text-center leading-10 font-extrabold tracking-tight text-grblack sm:text-5xl sm:leading-10">MT PASA DUID Availability</h1>
        <p class="text-xl text-gray-500 leading-8 font-semibold">
          The MT PASA DUID Availability widget displays the DUID availability detail from the most recent Medium Term Projected Assessment of System Adequacy (PASA) data for scheduled generators. It
          allows you to view dispatchable unit availability and compare availability with previous runs.
        </p>

        <div class="text-gray-500 mx-auto leading-7 font-normal text-lg">
          <br />
          <img class="w-2/3 rounded-m mx-auto" src="@/assets/mtpasa-image1.png" alt="MTPASA Widget Areas" />
          <br />
          <h2 class="mt-16 mb-4 text-4xl leading-12 font-bold tracking-tight text-grblack lg:leading-4">Description</h2>
          <p>
            PASA stands for "Projected Assessment of System Adequacy", and the primary purpose of the MT (Medium Term) PASA is to assess the adequacy of expected electricity supply to meet demand
            across a three-year horizon through regularly identifying and quantifying any projected failure to meet the reliability standard. This allows MT PASA results to be used to assess generator
            outage scheduling and risks to electricity supply reliability in the coming months and years.
          </p>
          <br />
          <p>
            This widget makes use of the high frequency three-hourly information service that, as of 2020, includes a DUID Availability report. This report file contains the PASA availability and the
            latest time an MTPASA offer was submitted for each scheduled DUID and each day over the current MTPASA run timeframe. The data is derived solely from the MTPASA offers provided by
            Scheduled Generators under clause 3.7.2 (d)(1) of the National Electricity Rules. If no MTPASA offer exists for a day, the latest PASA availability available is used.
          </p>
          <br />
          <p>
            The DUID Availability report is updated at 0900, 1200, 1500 and 1800 hrs EST each day (excluding Sundays).
          </p>
          <br />
          <img class="w-1/2 rounded-m mx-auto" src="@/assets/mtpasa-image2.png" alt="MTPASA Widget" />
          <br />
          <h2 class="mt-16 mb-4 text-4xl leading-12 font-bold tracking-tight text-grblack lg:leading-4">Toolbar</h2>
          <br />
          <img class="w-2/3 rounded-m mx-auto" src="@/assets/mtpasa-image3.png" alt="PASA Widget Toolbar" />
          <br />
          <p>The toolbar at the top of the widget allows you to control different aspects of the view. You can choose from two filter options to adjust which units are displayed.</p>

          <filters-quick />
          <filters-power />

          <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Runs and Range</h3>
          <p>
            The toolbar provides selectors to set the comparison run based on run date and the forecast range (should you be interested in shorter or longer outlook ranges). By default, the base run
            will always be the most recent (with respect to the application's current point in time).
          </p>
          <br/>
          <img class="w-1/4 rounded-m mx-auto" src="@/assets/mtpasa-image6.png" alt="MTPASA Widget Areas" />
          <br/>

          <h2 class="mt-16 mb-4 text-4xl leading-12 font-bold tracking-tight text-grblack lg:leading-4">DUID Availability Table</h2>
          <br />
          <img class="w-2/3 rounded-m mx-auto" src="@/assets/mtpasa-image4.png" alt="MTPASA Widget" />
          <br />
          <p>
            The table in the upper portion of the widget (below the toolbar) shows each unit's availability (MW) and change in availability since the <i>previous run</i> (as per the selection in the
            toolbar, as a percentage). Columns <i> Interval</i> (day), <i>Total Availability</i> (MW) and <i>Units Available</i>(number of) provide temporal context and an overview.
          </p>
          <br/>
          <p>
            The <i>Total Availability</i> column adds each unit's current run availabilities. Only units meeting filter criteria are included.
          </p>
          <br/>
          <p>
            The <i>Units Available </i> column counts the number of units with non-zero availability in the current run. Only units meeting filter criteria are included.
          </p>
          <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Colour highlighting</h3>
          <p>
            The table highlights cells within the table that have changed between runs using the colours yellow, orange and red. The colour coding used is further
            <router-link to="/design-principles/InBuiltAlerts" class="inline text-blue-600 hover:underline">explained here</router-link>.
          </p>
          <h2 class="mt-16 mb-4 text-4xl leading-12 font-bold tracking-tight text-grblack lg:leading-4">DUID Availability Chart</h2>
          <br />
          <img class="w-2/3 rounded-m mx-auto" src="@/assets/mtpasa-image5.png" alt="MTPASA Widget" />
          <br />
          <p>The chart displays the availability data for the unit or overview metric selected in the table. Select a table column by clicking on the table header.</p>
          <br />
          <p>
            The current and previous run values are displayed (indicated in the chart legend).
          </p>
          <br />
          <p>
            When there is a difference in values between runs, the bottom portion of the chart indicates the magnitude of the difference as bars. The bars will be coloured according to standard color
            coding principles, consistent with table colouring (or grey if the absolute difference is &lt; 5%).
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FiltersQuick from './FiltersQuick.vue';
import FiltersPower from './FiltersPower.vue';

export default {
  components: { FiltersQuick, FiltersPower },
  title: 'MT PASA DUID Availability'
};
</script>
