<template>
    <div class="relative py-16 bg-white overflow-hidden">
    <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
    </div>
    <div class="relative px-4 sm:px-6 lg:px-8">
        <div class="text-lg mx-auto mb-6">
            <router-link to="/widget-guides" class="block"><p class="text-base text-center leading-4 text-grblue font-semibold tracking-wide hover:underline">Widget Guides</p></router-link>
            <h1 class="mt-4 mb-10 text-4xl text-center leading-10 font-extrabold tracking-tight text-grblack sm:text-5xl sm:leading-10">Regional Consumption</h1>
            <p class="text-xl text-gray-500 leading-8 font-semibold">The Regional Consumption widget is a data map that displays an overview of regional demand for the National Electricity Market (NEM) and the Wholesale Electricity Market (WEM).  </p>
            <div class="text-gray-500 mx-auto leading-7 font-normal text-lg">
                <br/>
                <img class="w-2/3 rounded-m mx-auto" src="@/assets/regionalconsumption-image1.png" alt="Regional Consumption Widget">
                <br/>
                <h2 class="mt-16 mb-4 text-4xl leading-12 font-bold tracking-tight text-grblack lg:leading-4">Description</h2>
                <p>The widget includes data for both the NEM and the WEM - the WEM is responsible for the South West Interconnected System of Western Australia (SWIS). The map shows demand and current weather data for regional centres around Australia, helping you visualise and better understand the influence of temperature and humidity on demand.  </p>
                <br/>
                <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Regional Demand Total Data</h3>
                <br/>
                <img class="rounded-m mx-auto" src="@/assets/regionalconsumption-image2.png" alt="Regional Consumption Widget">
                <br/>
                <p>For regions within the NEM the demand value displayed in Operational Demand (DEMAND_AND_NONSCHEDGEN) which is met by: </p>
                <ul class="pl-15 pt-2 pb-2">
                    <li>• local scheduled generation (less local scheduled loads), </li>
                    <li>• semi-scheduled generation and non-scheduled wind/solar generation of aggregate capacity greater than or equal to 30 MW, </li>
                    <li>• and by generation imports to the region. </li>
                </ul>
                <br/>
                <p>The demand value displayed for the WEM is the Operational Load Estimate which is the average of the point-in-time estimates of the total load in the SWIS as provided by System Management. 
                    This value is based on loss factor-adjusted SCADA data.</p>
                <br/>
                <p>The colour of the region and associated data box heat-maps the magnitude of the current demand value on a sliding scale between the historical minimum and maximum values for the region, this is explained <router-link to="/design-principles/InBuiltAlerts" class="inline text-blue-600 hover:underline">here in more detail</router-link>. The historical values per region are displayed in a tool tip when you hover over the demand value. </p>
                <br/>
                <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Temperature Data</h3>
                <br/>
                <img class="rounded-m mx-auto" src="@/assets/regionalconsumption-image3.png" alt="Regional Consumption Widget">
                <br/>
                <p>Each of the dots on the map represents geographic weather data - using a thermal colouring scale based on the magnitude of the current temperature value between the 0 - 40 degrees Celsius. More information about colour thermaling in ez2view is <router-link to="/design-principles/InBuiltAlerts" class="inline text-blue-600 hover:underline">available here</router-link>. </p>
                <br/>
                <p>Hovering over each dot on the map display further details including: location name, temperature, apparent temperature, humidity and a timestamp of the data. </p>
            </div>
        </div>
    </div>
    </div>
</template>

<script>
export default {
    title: 'Regional Consumption'
};
</script>