<template>
  <!--
  Tailwind UI components require Tailwind CSS v1.8 and the @tailwindcss/ui plugin.
  Read the documentation to get started: https://tailwindui.com/documentation
-->
<!-- This component requires Tailwind CSS >= 1.5.1 and @tailwindcss/ui >= 0.4.0 -->
<div class="relative py-16 bg-white overflow-hidden">
  <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
  </div>
  <div class="relative px-4 sm:px-6 lg:px-8">
    <div class="text-lg mx-auto mb-6">
      <h1 class="mt-6 mb-10 text-4xl text-center leading-10 font-extrabold tracking-tight text-grblack sm:text-5xl sm:leading-10">Widget Guides</h1>
      <p class="text-xl text-gray-500 leading-8 font-semibold">We provide help documentation for each widget within ez2view. These 'Widget Guides' include a summary of their functionality along with instructions for use. </p>
      <br/>
      <div class="prose-lg text-gray-500 mx-auto">
      <p>You can access these help pages within the desktop software by clicking on the '?' button in the top right hand corner of a widget, as shown in the image below. By clicking this button you will be taken to an online guide for that specific widget. </p>
      <br/>
      <img class="w-full lg:w-3/4 mx-auto" src="@/assets/widgetguides-image1.png" alt="Widget Guides">  
      <br/>
      <p>If you require additional assistance with using any of our widgets, please <router-link to="/contact-us" class="text-blue-600 hover:underline">contact our support team</router-link> directly.</p>
      </div>
    </div>
  </div>
</div>

</template>

<script>
export default {
    title: 'Widget Guides'
};
</script>