<template>
    <div class="relative py-16 bg-white overflow-hidden">
    <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
    </div>
    <div class="relative px-4 sm:px-6 lg:px-8">
        <div class="text-lg mx-auto mb-6">
            <router-link to="/widget-guides" class="block"><p class="text-base text-center leading-4 text-grblue font-semibold tracking-wide hover:underline">Widget Guides</p></router-link>
            <h1 class="mt-4 mb-10 text-4xl text-center leading-10 font-extrabold tracking-tight text-grblack sm:text-5xl sm:leading-10">Unit Meta Data</h1>
            <p class="text-xl text-gray-500 leading-8 font-semibold">More information about this widget will be published soon. Please <router-link to="/contact-us" class="text-blue-600 hover:underline">contact us</router-link> if you require immediate assistance with this widget. </p>
            <div class="text-gray-500 mx-auto leading-7 font-normal text-lg">
            </div>
        </div>
    </div>
    </div>
</template>

<script>
export default {
    title: 'Unit Meta Data'
};
</script>