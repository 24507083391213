<template>
  <div class="relative py-16 bg-white overflow-hidden">
    <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
    </div>
    <div class="relative px-4 sm:px-6 lg:px-8">
      <div class="text-lg mb-2 mx-auto">
          <router-link to="/design-principles" class="block"><p class="text-base text-center leading-4 text-grblue font-semibold tracking-wide hover:underline">Design Principles</p></router-link>
      </div>
      <nav class="border-t border-gray-200 px-4 flex items-center justify-between sm:px-0">
          <div class="-mt-px w-0 flex-1 flex">
              <router-link to="/design-principles/builddashboard" class="border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-md font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300">
              <!-- Heroicon name: arrow-narrow-left -->
              <svg class="mr-3 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z" clip-rule="evenodd" />
              </svg>
              <p>Previous: <b> Build Your Own Dashboard</b></p>
              </router-link>
          </div>
          <div class="hidden md:-mt-px md:flex">
          </div>
          <div class="-mt-px w-0 flex-1 flex justify-end">
              <router-link to="/design-principles/hyperlinks" class="border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-md font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300">
              <p>Next: <b> Context-sensitive Linkages</b></p>
              <!-- Heroicon name: arrow-narrow-right -->
              <svg class="ml-3 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd" />
              </svg>
              </router-link>
          </div>
      </nav> 
      <div class="text-lg mx-auto mb-6">
          <h1 class="mt-6 mb-10 text-4xl text-center leading-10 font-extrabold tracking-tight text-grblack sm:text-5xl sm:leading-10">Point in Time View</h1>
          <p class="text-xl text-gray-500 leading-8 font-semibold">The NEM is such a complex place that it’s rarely a single variable that dictates any significant outcome. Therefore, it is necessary to see many different variables at a single point in time to understand what’s important. For these reasons, we have designed the software in a way that allows our users to easily inspect and switch between historical and live data.</p>
      </div>
      <div class="text-gray-500 mx-auto leading-7 font-normal text-lg">
          <br/>
          <p>In the <router-link to="/getting-started/builddashboard" class="text-blue-600 hover:underline">'Build Your Own Dashboard'</router-link> section of this site we have explained the basic interface of ez2view including what widgets are and how they exist within windows and layouts. On this page we will explain the time-specific nature of the underlying data in these widgets and more specifically, how you can switch between viewing live data and historical data.  </p>
          <br/>
          <p>As you would already be aware, the NEM is dispatched in 5-minute intervals. This means that new data becomes available every 5 minutes. We have designed ez2view around these 5-minute dispatch intervals, so that users can observe and analyse the market interval-by-interval. </p>
          <br/>
          <p>On the top right-hand corner of each window of ez2view you will find the ez2view clock, this clock contains various pieces of information which we explain later below. </p>
              <img class="w-full lg:w-1/2 mx-auto" src="@/assets/pointintime-image1.png" alt="ez2view Clock">  
          <p>Clicking on this clock, as shown in the image above, will open the time-travel panel. This panel allows you to switch between two modes:</p>
          <ol class="pl-12 pt-2 pb-2 list-decimal">
              <li><b>Real-time mode</b> – widgets will display up-to-date ‘live’ data and will continually update when new data becomes available.</li>
              <li><b>Time-travel mode</b> - you specify a historical dispatch interval, and widgets will display data relating to that specific point-in-time.  </li>
          </ol>
          <p>Below we have provided more details about each of these modes, and we explain how you can explore multiple different points-in-time simultaneously.</p>
          <br/>
          <h2 class="mt-16 mb-4 text-4xl leading-12 font-bold tracking-tight text-grblack lg:leading-4">Real-time Mode</h2>
          <br/>
          <img class="w-full lg:w-1/3 mx-auto" src="@/assets/pointintime-image2.png" alt="Time Travel Panel in Real-Time">  
          <br/>
          <p>By default, a window will be in “real time” mode. In this mode all data you are seeing is essentially live, i.e. up-to-date and applicable for the current dispatch interval. While you are using real-time mode, widgets will update as and when new data is available.  </p>
          <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">ez2view Clock</h3>
          <p>When in real-time mode, the graphic on the ez2view clock in the top right of your screen indicates various pieces of information about the current and next dispatch interval.</p>
          <br/>
          <img class="w-full lg:w-1/2 mx-auto" src="@/assets/pointintime-image3.png" alt="ez2view Clock">  
          <br/>
          <p>The clock will appear with a red warning, as shown below, should your data updates fail for whatever reason e.g. data supply to your Private MMS is disrupted.</p>
          <br/>
          <img class="w-full lg:w-1/3 mx-auto" src="@/assets/pointintime-image4.png" alt="ez2view Clock Warning"> 
          <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Important things to note about real-time mode</h3>
          <ul class="pl-12 pt-2 pb-2 list-disc">
              <li>The NEM operates on AEST (UTC +10:00) year-round, and hence daylight savings is not observed. </li>
              <li>As per market protocol, each interval is identified by it’s end time. For example: if the time is 09:32AM, then the data relates to the 09:35 dispatch interval. </li>
              <li>Different data sets will arrive on different cadences and are of different time steps – hence the application will show data as close to real time as possible. The cadences of some of these data sets are listed below:
                <ul class="pl-16 list-square">
                  <li>AEMO dispatch data is published on 5-minute intervals.</li>
                  <li>AEMO trading data is published on 30-minute intervals. On the 1st of October 2021, the NEM will move to 5-minute settlement, meaning that trading data will be published on 5-minute intervals.</li>
                  <li>AEMO P5 predispatch data is published on 5-minute intervals. </li>
                  <li>AEMO P30 predispatch data is published on 30-minute intervals and extends out until 04:00am of the successive day.</li>
                  <li>AEMO bidding data is published at 04:00am on the successive day.</li>
                  <li>BOM Weather observations are generally published on 10-minute intervals.</li>
                  <li>ASX Energy Futures data is published at the end of each ASX trading day.</li>
                  <li>LGC registration data is published once annually by the Clean Energy Regulator.</li>
                </ul>
              </li>
          </ul>
          <br/>
          <h2 class="mt-16 mb-4 text-4xl leading-12 font-bold tracking-tight text-grblack lg:leading-4">Time-Travel Mode</h2>
          <br/>
          <img class="w-full lg:w-1/3 mx-auto" src="@/assets/pointintime-image5.png" alt="Time Travel Panel in Real-Time">  
          <br/>
          <p>In time-travel mode, you specify an individual dispatch interval and all widgets within the window will show the market as it appeared at that point in time. This mode is designed for you to conduct forensic analysis of specific historic events so that you can examine what happened in the market after-the-fact. Over 10 years’ worth of NEM data is available in time-travel mode, meaning you can travel back to any individual 5-minute dispatch interval since mid-2010.</p>
          <br/>
          <p>To activate this mode, simply click on the toggle that appears in the time-travel panel. You can toggle back to real-time mode by doing the same action, as shown below.</p>
          <br/>
          <img class="w-1/6 mx-auto" src="@/assets/pointintime-image6.gif" alt="Time Travel Panel in Real-Time" style="height: 120px; width: 190px; -webkit-font-smoothing: antialised; backface-visibility: hidden; transform: translateZ(0); -webkit-font-smoothing: subpixel-antialiased;"> 
          <br/>
          <p>Once you have toggled to time-travel mode, you can click on the ‘Jump to Time…” button to navigate to a specific historical dispatch interval. Additionally, you can use the left arrow and right arrow buttons to navigate backwards and forwards through 5 minute dispatch intervals.</p>
          <br/>
          <img class="w-full lg:w-1/3 mx-auto" src="@/assets/pointintime-image7.png" alt="Jump to Time"> 
          <br/>
          <img class="w-full lg:w-1/3 mx-auto" src="@/assets/pointintime-image8.png" alt="Make the Time"> 
          <br/>
          <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Important things to note about real-time mode</h3>
          <ul class="pl-12 pt-2 pb-2 list-disc">
              <li>In time-travel mode, all forecast data will display as it existed at the specified point in time – i.e. P5 predispatch, P30 predispatch, ST PASA and MT PASA. You can use the forecast convergence widget to specifically analyse how forecasts change over time.</li>
              <li>If there were price revisions subsequently released, these will be updated in our database and reflected in this mode.</li>
              <li>Some web widgets do not currently support time-travel mode. In these cases, the current (live) time is specified within the widget itself.</li>
          </ul>
          <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Analysing Multiple Points-in-time at Once</h3>
          <p>Previously, we have explained how you can open and configure new windows within ez2view. It’s important to note that the time-travel feature applies to each individual window that you have opened – this critically means that you can use both modes simultaneously, or alternatively, you could examine multiple historical dispatch intervals at once. You can do this by simply opening multiple windows and configuring the time-travel feature for each.</p>
          <br/>
          <img class="w-full lg:w-1/2 mx-auto" src="@/assets/pointintime-image9.png" alt="Time Travel with Multiple Windows"> 
          <br/>
          <p>When you have multiple windows open in time-travel mode, the title bars get automatically coloured-coded, as seen in the image above, so that you can more easily distinguish each window and each point-in-time.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    title: 'Point in Time View'
};
</script>
