<template>
    <div class="relative py-16 bg-white overflow-hidden">
    <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
    </div>
    <div class="relative px-4 sm:px-6 lg:px-8">
        <div class="text-lg mx-auto mb-6 pb-2 border-b-1">
        <router-link to="/getting-started" class="block"><p class="text-base text-center leading-4 text-grblue font-semibold tracking-wide hover:underline">Getting Started</p></router-link>
        <h1 class="mt-6 mb-10 text-4xl text-center leading-10 font-extrabold tracking-tight text-grblack sm:text-5xl sm:leading-10">ez2update Installer</h1>
        <p class="text-xl text-gray-500 leading-7 font-semibold">Only a <i>small</i> number of our clients need to use ez2update. This software provides a stand-alone data feed designed for a small number of our clients who require this service, and as such it is not a necessary component of the software for the majority of our customers. </p>
        </div>
        <div class="text-gray-500 mx-auto leading-7 font-normal text-lg">
            <p>To understand if you need to install ez2update before installing and configuring ez2view Desktop, please read our section about <router-link to="/getting-started/datadelivery" class="text-blue-600 hover:underline">data delivery</router-link>,  or <router-link to="/contact-us" class="text-blue-600 hover:underline">contact us</router-link> directly. </p>
            <br/>
        </div>
        <div class="bg-white">
            <div class="max-w-screen-xl mx-auto bg-white py-16 sm:px-6 lg:px-8">
                <!-- xs to lg -->
                <div class="max-w-2xl mx-auto lg:hidden">
                    Please use a desktop web browser to view the table and access install files.
                </div>

                <!-- lg+ -->
                <div class="hidden lg:block">
                <table class="w-full h-px table-fixed">
                    <caption class="sr-only">
                    Release Comparison
                    </caption>
                    <thead>
                    <tr>
                        <th class="w-1/3 pb-4 px-6 text-sm leading-5 font-medium text-gray-800 text-left" scope="col">
                        <span class="sr-only">Release Type</span>
                        <span>Release</span>
                        </th>
                        <th class="w-2/3 pb-4 px-6 text-3xl leading-6 font-bold text-gray-800 text-left" scope="col">Current Version</th>
                    </tr>
                    </thead>
                    <tbody class="border-t border-gray-200 divide-y divide-gray-200">
                    <tr>
                        <th class="py-8 px-6 text-sm leading-5 font-medium text-gray-900 text-left align-top" scope="row">Version</th>

                        <td class="h-full py-8 px-6 align-top">
                        <div class="relative h-full table">
                            <p>
                            <span class="text-base leading-6 font-semibold text-gray-500">January 2020</span>
                            </p>
                            <p>
                            <span class="text-base leading-6 text-gray-500">v5.5</span>
                            </p>
                            <p class="mt-6 mb-8 text-base leading-5 text-gray-500">New versions are released periodically to utilise new data sets and to comply with updates to operating systems.</p>
                            <p class="mt-4 mb-16 text-sm leading-5 text-gray-500">Requires Database Maintenance Scripts (Reaper) v19</p>
                            <button type="button" class="absolute bottom-0 flex-grow w-full bg-gray-700 border border-gray-700 rounded-md 5 py-2 text-sm leading-5 font-semibold text-white hover:bg-gray-900 focus:outline-none focus:ring-gray transition duration-150 ease-in-out"><a href="https://installers.global-roam.com/ez2update/Install_ez2Update_5.5.0.231_x64.msi">Download ez2update</a></button>
                        </div>
                        </td>
                    </tr>

                    </tbody>
                    <tfoot>
                    </tfoot>
                </table>
                </div>
            </div>
        </div>
        <div class="text-gray-500 mx-auto leading-7 font-normal text-lg">
            <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Licence Agreement</h3>
            <p>Please review the <a href="https://downloads.global-roam.com/licence/Global-Roam_SoftwareLicenceAgreement.pdf" class="text-blue-600 hover:underline">licence agreement</a>, which will apply to your full licence of ez2view.</p>
            <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Installation Instructions</h3>
            <p>Please refer to the <a href="https://downloads.global-roam.com/ez2update/ez2updateAustralia_Installation_Instructions_v5.5.pdf" class="text-blue-600 hover:underline">installation instructions</a> before installing ez2view.</p>
            <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">System Requirements</h3>
            <p>You will need the <a href="https://downloads.global-roam.com/ez2update/ez2update_Australia_Maintenance_v19.zip" class="text-blue-600 hover:underline">Database Maintenance Scripts (Reaper) v19</a> to maintain your database at a manageable size. This file is zipped – you will need to extract the SQL script to run it.</p>
        </div>
    </div>
    </div>
</template>

<script>
    export default {
        title: 'ez2update Installer'
    };
</script>
