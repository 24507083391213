<template>
    <div class="relative py-16 bg-white overflow-hidden">
    <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
    </div>
    <div class="relative px-4 sm:px-6 lg:px-8">
        <div class="text-lg mx-auto mb-6">
            <router-link to="/widget-guides" class="block"><p class="text-base text-center leading-4 text-grblue font-semibold tracking-wide hover:underline">Widget Guides</p></router-link>
            <h1 class="mt-4 mb-10 text-4xl text-center leading-10 font-extrabold tracking-tight text-grblack sm:text-5xl sm:leading-10">Constraint Dashboard</h1>
            <p class="text-xl text-gray-500 leading-8 font-semibold">The Constraint Dashboard widget visualises the values and factors associated with the Left Hand Side (LHS) of a constraint equation. It is focused on helping the user interpret the LHS terms to understand how individual units contribute to the binding of the constraint. </p>
            <div class="text-gray-500 mx-auto leading-7 font-normal text-lg">
                <br/>
                <img class="w-4/5 rounded-m mx-auto" src="@/assets/constraintdashboard-image1.png" alt="Constraint Dashboard">
                <br/>
                <h2 class="mt-16 mb-4 text-4xl leading-12 font-bold tracking-tight text-grblack lg:leading-4">Description</h2>
                <p>The widget presents a chart on the left and a table on the right. The chart presents the LHS term-contributions of the selected constraint equation for each interval in the time range. The table presents a more detailed view of each LHS term for the specific interval selected. </p>
                <br/>
                <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Toolbar</h3>
                <img class="w-1/3 rounded-m mx-auto" src="@/assets/constraintdashboard-image2.png" alt="Constraint Dashboard Toolbar">
                <br/>
                <p>Use this to select the constraint and the time range for the chart. Click on the magnifying glass icon, and type in the constraint ID to select which constraint you want to display data for. Here you can also set the lookback and look forward periods for the chart. </p>
                <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Charts</h3>
                <p>Two time-series charts are stacked on the left side of the widget.</p>
                <img class="w-2/3 rounded-m mx-auto" src="@/assets/constraintdashboard-image3.png" alt="Constraint Dashboard Top Left Chart">
                <br/>
                <p>The chart at the top is a stacked area graph which shows the MW contribution (LHS Factor multiplied by Target) for each LHS term. These are colour-coded to what's shown in the table on the right side of the widget. Terms that have a negative contribution are also displayed. </p>
                <br/>
                <p>The dotted black line in the chart represents the RHS value while the solid black line represents the the sum of the LHS contributions (the LHS total). </p>
                <br/>
                <img class="w-2/3 rounded-m mx-auto" src="@/assets/constraintdashboard-image4.png" alt="Constraint Dashboard Bottom Left Chart">
                <br/>
                <p>The bottom chart consists of two sub-charts which support identification of periods when the constraint is binding and the magnitude of its impact (represented by the marginal value): </p>
                <ol class="pl-12 pt-2 pb-2 list-disc">
                    <li><b>Bound duration</b> is filled when the constraint was bound or is forecast to bind, given the current point in time. </li>
                    <li><b>Marginal value</b> is filled to the level of the marginal value of the constraint. </li>
                </ol>
                <br/>  
                <br/>
                <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Table</h3>
                <br/>
                <img class="w-1/3 rounded-m mx-auto" src="@/assets/constraintdashboard-image5.png" alt="Constraint Dashboard Table">
                <br/>
                <p>The table on the right side of the widget displays further information about the LHS terms of the equation. The columns in this table represent:</p>
                <ol class="pl-12 pt-2 pb-2 list-disc">
                    <li><b>ID</b> = The ID of the unit or interconnector or region.</li>
                    <li><b>Factor</b> = The LHS factor of the term. Shading represents the size of the factor.</li>
                    <li><b>Target/Available</b> = The Ratio of the MW Target in relation to it's availability for the given dispatch interval. Shading is relative to the maximum capacity - grey represents the target, a red portion represents the remaining available capacity.</li>
                    <li><b>Contribution</b> = The LHS factor multiplied by the Target in MW. Shading represents this value relative to the largest contributor's contribution.</li>
                    <li><b>Rebid</b> = The dark icon indicates that the bid had materially changed (could affect the outcome of NEMDE) since the prior interval. 
                                       The faded icon indicates that a new bid for the unit was submitted, with immaterial difference.
                                       No icon indicates the bid had not changed (or there was no bid). </li>
                    <li><b>Last MW @</b> = The bid band price from which the last MW was dispatched. Shading represents the volume of that bid band that was dispatched relative to the volume offered.</li>
                    <li><b>Next MW @</b> = The bid band that the next MW would be dispatched from. </li>
                    <li><b>CPD Price</b> = The Connection Point Dispatch Price of the unit.</li>
                    <li><b>RRP</b> = The Regional Reference Price for the unit's region.</li>
                </ol>
                <br/>  
                <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Table Interactivity</h3>    
                <br/>
                <p>Rows on the table may be mouse-clicked to select them. When selected, that unit's contributions are highlighted in the chart. </p> 
                <p>Multiple rows can be selected with ctrl-click or shift-click. Deselect rows with the same action.</p>      
                <br/>  
                <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Data Availability</h3>
                <br/>
                <p>The 5-minute predispatch data at the unit-level is next-day public. This is the period between (but excluding) the current interval and 60 minutes into the future. When not available, unit-level data in the chart and table may be absent. In this situation only regional and interconnector constraint contributions will be able to be displayed.</p>
                <br/>
            </div>
        </div>
    </div>
    </div>
</template>

<script>
export default {
    title: 'Constraint Dashboard'
};
</script>