<template>
    <div class="relative py-16 bg-white overflow-hidden">
    <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
    </div>
    <div class="relative px-4 sm:px-6 lg:px-8">
        <div class="text-lg mx-auto mb-6">
            <router-link to="/widget-guides" class="block"><p class="text-base text-center leading-4 text-grblue font-semibold tracking-wide hover:underline">Widget Guides</p></router-link>
            <h1 class="mt-4 mb-10 text-4xl text-center leading-10 font-extrabold tracking-tight text-grblack sm:text-5xl sm:leading-10">NEM Summary Stats</h1>
            <p class="text-xl text-gray-500 leading-8 font-semibold">The NEM Summary Stats widget provides an overview of the current state of the entire NEM - aggregating availability, demand and generation totals for all of it's five regions. </p>
            <div class="text-gray-500 mx-auto leading-7 font-normal text-lg">
                <br/>
                <img class="w-1/2 rounded-m mx-auto" src="@/assets/nemsummarystats-image1.png" alt="Gen Change Widget">
                <br/>
                <h2 class="mt-16 mb-4 text-4xl leading-12 font-bold tracking-tight text-grblack lg:leading-4">Description</h2>
                <p>The NEM Summary Stats shows displays a table and chart of availability, demand and generation totals across the NEM as a whole, as well as time-series charts of aggregated metered generation and emissions rate by region.</p>
                <br/>
                <h2 class="mt-16 mb-4 text-4xl leading-12 font-bold tracking-tight text-grblack lg:leading-4">NEM Summary Table</h2>
                <p>The summary table shows the following total for the current dispatch interval:</p>
                <ul class="pl-15 pt-2 pb-2">
                    <li>• <b>NEM-wide Availability</b> - Total Availability in all five regions</li>
                    <li>• <b>NEM-wide Demand</b> - Total Demand in all five regions</li>
                    <li>• <b>Generation Surplus</b> - NEM-wide Availability minus NEM-wide Demand</li>
                    <li>• <b>Instantaneous Reserve Plant Margin (IRPM)</b> - NEM-wide Demand divided by Generation Surplus, as a percentage.</li>
                </ul>
                <h2 class="mt-16 mb-4 text-4xl leading-12 font-bold tracking-tight text-grblack lg:leading-4">NEM Summary Chart</h2>
                <p>The NEM Summary Chart shows a time-series overlapping area chart of NEM-wide Availability and NEM-wide Demand. The x-axis ranges from actual data for the past 6 hours and forecast data for the next 18 hours. </p>
                <h2 class="mt-16 mb-4 text-4xl leading-12 font-bold tracking-tight text-grblack lg:leading-4">Generation by Region</h2>
                <p>The Generation by Region Chart shows a time-series stacked area chart of metered generation in each region for the past 2 hours. Hovering over the table displays a panel with the exact figures for each region. </p>
                <h2 class="mt-16 mb-4 text-4xl leading-12 font-bold tracking-tight text-grblack lg:leading-4">Emissions Rate by Region</h2>
                <p>The Emissions Rate by Region Chart shows a time-series stacked area chart of generation emissions (tonnes CO2e/hour) in each region for the past 2 hours. Hovering over the table displays a panel with the exact figures for each region. </p>
            </div>
        </div>
    </div>
    </div>
</template>

<script>
export default {
    title: 'NEM Summary Stats'
};
</script>