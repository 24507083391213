<template>
    <p>Redirecting to <a :href="url" target="_blank">{{ url }}</a></p>
</template>

<script>
export default {
    data() {
        return {
            url: 'https://timeline.ez2viewaustralia.info/'
        }
    },
    mounted() {
        // window.location.href = this.url;
        window.open(this.url);
    }
}
</script>
