<template>
    <div class="relative py-16 bg-white overflow-hidden">
    <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
    </div>
    <div class="relative px-4 sm:px-6 lg:px-8">
        <div class="text-lg mx-auto mb-6 pb-6 border-b-1">
            <router-link to="/complex-market-concepts" class="block"><p class="text-base text-center leading-4 text-grblue font-semibold tracking-wide hover:underline">Complex Market Concepts</p></router-link>
            <h1 class="mt-6 mb-10 text-4xl text-center leading-10 font-extrabold tracking-tight text-grblack sm:text-5xl sm:leading-10">Regions and Interconnectors</h1>
            <p class="text-xl text-gray-500 leading-8 font-semibold font-italic">The grid that underlies the NEM was not built as a standalone electricity system. It was instead formed in 1998 as an arrangement between several previously independent state-based electricity commisions. That arrangement allowed for a single cross-state wholesale market (i.e. the NEM) to be managed by a single operator (The AEMO) to centrally coordinate the dispatch of electricity.</p>
        </div>
        <div class="text-gray-500 mx-auto leading-7 font-normal text-lg">
            <div class="p-4 w-1/3 rounded-md bg-gray-100 border-2 overflow-hidden">
                <h3 class="mb-2 text-xl font-semibold tracking-tight text-grblack sm:text-xl text-center">Sections on this page</h3>
                <p class="pt-8 inline text-grblue font-bold">Regions and Interconnectors</p>
                <ol class="pl-8 pt-2 list-decimal">
                    <li><a href="#inthenem" class="inline text-grblue hover:underline font-bold">In the NEM</a></li>
                    <ol class="pl-8 list-lower-alpha">
                        <li><a href="#aemowem" class="inline text-grblue hover:underline">Regions</a></li>
                        <li><a href="#asxenergy" class="inline text-grblue hover:underline">Interconnectors</a></li>
                        <li><a href="#aemowem" class="inline text-grblue hover:underline">Islanding Events</a></li>
                        <li><a href="#asxenergy" class="inline text-grblue hover:underline">Further Reading</a></li>
                    </ol>
                    <li><a href="#bom" class="inline text-grblue hover:underline font-bold">In ez2view</a></li>
                    <ol class="pl-8 list-lower-alpha">
                        <li><a href="#aemowem" class="inline text-grblue hover:underline">Regional Schematics</a></li>
                        <li><a href="#asxenergy" class="inline text-grblue hover:underline">Interconnector Details</a></li>
                    </ol>
                </ol>
            </div>
            <br/>
            <p class="italic">This page is largerly a compilation of excerpts from several articles that have been posted on WattClarity. These articles are listed under the 'Further Reading' header.</p>
            <br/>
            <p>Electricity markets around the world are generally classified as either 'zonal' or 'nodal'. In a zonal market, price formulation occurs at a macro level - typically for all connection points within a defined geographic area. A nodal market in contrast is a more intricate market design where price formulation occurs at a much more micro-level - typically for each individual connection point.</p>
            <br/>
            <img class="mx-auto w-full sm:w-1/3" src="@/assets/regions-image5.jpg" alt="NEM Regions & Interconnectors">
            <p class="font-bold text-center mx-auto w-2/3">The electricity market for the US state of Texas, operated as a zonal market (left) until December 2010. It was then redesigned to a nodal market structure (right). </p>
            <p class="italic text-center"> Source: ElectricChoice </p>
            <br/>
            <p>This would be analogous to your council rates being calculated on a suburb-to-suburb basis (zonal),  or being calculated on an individual property-to-property basis (nodal). </p>
            <h2 class="mt-16 mb-4 text-4xl leading-12 font-bold tracking-tight text-grblack lg:leading-4" id="inthenem">In the NEM</h2>
            <p>In a traditoinal sense, the NEM is a zonal electricity market. In the NEM there are five zones, however they are more commonly referred to as 'regions'. The five regions (QLD, NSW, VIC, SA and TAS) loosely represent the geographic area of their corresponding states. </p>
            <br/>
            <p>The dispatch process (and hence price setting) is run for all regions simultaneoulsy at the start of each of five minute interval. The existance of interconnectors mean that the formulation of price in each region is not independent. These interconnectors are incorporated into the </p>
            <br/>
            <img class="mx-auto w-full sm:w-1/3" src="@/assets/regions-image3.png" alt="NEM Regions & Interconnectors">
            <p class="font-bold text-center">A simple five-node model of the NEM</p>
            <br/>
            <p>While the NEM is not technically classified as a nodal market, it is important to note that there are elements of localised price formulation in its current market design. This occurs through two primary market mechanisms: Contraint Equations and Marginal Loss Factors.</p>
            <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Regions</h3>
            <p>Each region represents a subsection of the physical network, and each is managed managed by a single Transmission Network Service Provider (TNSP). It is the role of the TNSP to plan, provide and manage the physical tranmission network for their region, which includes working closely with the AEMO to ensure the integration of the physicical grid with the energy market.   </p>
            <br/>
            <p>In Victoria only, the AEMO plays a unique role in that it also plans the state's transmission network.</p>
            <br/>
                <button data-tooltip-target="tooltip-default" type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Default tooltip</button>
                <div id="tooltip-default" role="tooltip" class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                    Tooltip content
                    <div class="tooltip-arrow" data-popper-arrow></div>
                </div>
            <div class="mx-auto flex flex-col overflow-x-scroll lg:overflow-hidden">
                <div class="mx-auto">
                    <div class="mx-auto px-16 py-8 text-center align-middle inline-block sm:px-16 lg:px-52">
                        <div class="mx-auto shadow border-b border-gray-200 sm:rounded-lg">
                            <table class="mx-auto divide-y divide-gray-200">
                            <thead>
                                <tr>
                                <th class="px-2 py-2 bg-gray-100 leading-5 text-grblack w-2/12">
                                    <p class="text-lg text-center font-bold">Region ID</p>
                                </th>
                                <th class="px-2 py-2 bg-gray-100 leading-5 text-grblack w-4/12">
                                    <p class="text-lg text-center font-bold">Geographic Area</p>
                                    <p class="text-sm text-center italic">Indicative only</p>
                                </th>
                                <th class="px-2 py-2 bg-gray-100 leading-5 text-grblack w-2/12">
                                    <p class="text-lg text-center font-bold">Regional Reference Node</p>
                                </th>
                                <th class="px-2 py-2 bg-gray-100 leading-5 text-grblack w-3/12">
                                    <p class="text-lg text-center font-bold">Transmission Network Service Provider</p>
                                </th>
                                <th class="px-2 py-2 bg-gray-100 leading-5 text-grblack w-1/12">
                                    <p class="text-lg text-center font-bold">Incorporated into the NEM</p>
                                </th>
                                </tr>
                            </thead>
                            <tbody>
                                <!-- Odd row -->
                                <tr class="bg-white">
                                <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                                    QLD
                                </td>
                                <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                    <img class="mx-auto w-full sm:w-1/4 pb-2" src="@/assets/regions-image5.png" alt="QLD Region Boundaries">
                                    <p class="font-semibold">Queensland</p>
                                    <br/>
                                    excludes large parts of the state's west, and several islands
                                </td>
                                <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                    South Pine
                                </td>
                                <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                    Powerlink
                                </td>
                                <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                    1998
                                </td>
                                </tr>
                                <!-- Even row -->
                                <tr class="bg-gray-50">
                                <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                                    NSW
                                </td>
                                <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                    <img class="mx-auto w-full sm:w-1/4 pb-2" src="@/assets/regions-image6.png" alt="NEM Regions & Interconnectors">
                                    <p class="font-semibold">New South Wales + Australian Capital Territory</p>
                                    <br/>
                                    excludes large parts of the state's north-west
                                </td>
                                <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                    West Sydney
                                </td>
                                <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                    TransGrid
                                </td>
                                <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                    1998
                                </td>
                                </tr>
                                <!-- Odd row -->
                                <tr class="bg-white">
                                <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                                    VIC
                                </td>
                                <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                    <img class="mx-auto w-full sm:w-1/4 pb-2" src="@/assets/regions-image7.png" alt="NEM Regions & Interconnectors">
                                    <p class="font-semibold">Victoria</p>
                                    <br/>
                                    excludes some islands
                                </td>
                                <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                    Thomastown
                                </td>
                                <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                    AusNet Services
                                </td>
                                <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                    1998
                                </td>
                                </tr>
                                <!-- Even row -->
                                <tr class="bg-gray-50">
                                <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                                    SA
                                </td>
                                <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                    <img class="mx-auto w-full sm:w-1/4 pb-2" src="@/assets/regions-image8.png" alt="NEM Regions & Interconnectors">
                                    <p class="font-semibold">South Australia</p>
                                    <br/>
                                    excludes large parts of the state's north and west
                                </td>
                                <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                    Torrens Island
                                </td>
                                <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                    ElectraNet
                                </td>
                                <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                    1998
                                </td>
                                </tr>
                                <!-- Odd row -->
                                <tr class="bg-white">
                                <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                                    TAS
                                </td>
                                <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                    <img class="mx-auto w-full sm:w-1/4 pb-2" src="@/assets/regions-image9.png" alt="NEM Regions & Interconnectors">
                                    <p class="font-semibold">Tasmania</p>
                                    <br/>
                                    excludes several islands
                                </td>
                                <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                    George Town
                                </td>
                                <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                    TasNetworks
                                </td>
                                <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                    2005
                                </td>
                                </tr>
                            </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <p>The NEM began operations in 1998. TAS joined the NEM in 2005. Previously the SNOWY region covered the geographic area around the Snowy Mountains, this region was abolished in 2008.</p>
            <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Interconnectors</h3>
            <p>Interconnectors are the bridges that link the five regions together and allow for inter-regional exports and imports. However, unlike a bridge, they are not so much a single physical structure. They are, instead, a construct of the mathematical model that underpins the NEM (i.e. the NEM Dispatch Engine).</p> 
            <br/>
            <p>In the NEM, interconnectors come in the form of either AC (Alternating Current) or DC (Direct Current). It’s probably easier to conjure up an image of a DC interconnector, as the infrastructure itself is more-or-less a discrete cable (or group of cables) from one region to another with a terminal on either end. AC interconnectors on the other hand are more abstract. In these cases, the ‘interconnector’ is all parts of a long complex series of essential transmission lines that connect one RRN to another, and it can even be the aggregate flow of several cross-regional transmission paths.</p>
            <br/>
            <img class="mx-auto w-full sm:w-2/5" src="@/assets/regions-image2.png" alt="NEM Regions & Interconnectors">
            <br/>
            <p class="font-bold text-center">To further explain how the interconnectors work, we can incorporate the regional reference nodes into our simple five-node model that we showed earlier.</p>
            <br/>
            <p>It is these AC interconnectors that maintain a common system frequency across regions, as the DC interconnectors have no such ability. A key benefit of AC interconnection is that some tasks (such as procuring enough energy to manage system frequency) can be shared across regions. However, an unexpected trip of one of these interconnectors can lead to rather large system security issues, price spikes and other economic consequences as one region is suddenly ‘on its own’ to manage supply, frequency, anpuld the other complexities of operating an independent electricity grid.</p>
            <br/>
            <p>As of 2023, there are currently six operational interconnectors in the NEM: </p>
            <div class="mx-auto flex flex-col">
                <div class="mx-auto">
                    <div class="mx-auto px-8 py-8 align-middle text-center inline-block sm:px-8 lg:px-8">
                        <div class="mx-auto shadow border-b border-gray-200 sm:rounded-lg">
                            <table class="mx-auto divide-y divide-gray-200">
                            <thead>
                                <tr>
                                <th class="px-2 py-2 bg-gray-100 leading-2 text-grblack">
                                    <p class="text-lg text-center leading-6 font-bold">Interconnector ID</p>
                                </th>
                                <th class="px-2 py-2 bg-gray-100 leading-4 text-grblack">
                                    <p class="text-lg text-center leading-6 font-bold">Common Name</p>
                                </th>
                                <th class="px-2 py-2 bg-gray-100 leading-4 text-grblack">
                                    <p class="text-lg text-center leading-6 font-bold">Regions Connected</p>
                                </th>
                                <th class="px-2 py-2 bg-gray-100 leading-4 text-grblack">
                                    <p class="text-lg text-center leading-6 font-bold">Current</p>
                                </th>
                                <th class="px-2 py-2 bg-gray-100 leading-4 text-grblack">
                                    <p class="text-lg text-center leading-6 font-bold">Year Commisionned</p>
                                </th>
                                <th class="px-2 py-2 bg-gray-100 leading-4 text-grblack">
                                    <p class="text-lg text-center leading-6 font-bold">Nominal Capacity</p>
                                </th>
                                </tr>
                            </thead>
                            <tbody>
                                <!-- Odd row -->
                                <tr class="bg-white">
                                <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                                    N-Q-MNSP1
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-900">
                                    Terranora 
                                    <br/>
                                    (a.k.a Directlink)
                                </td>
                                <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                    NSW & QLD
                                </td>
                                <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                    DC
                                </td>
                                <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                    2000
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                                    <tr class="bg-white">
                                        <td class="whitespace-nowrap text-sm leading-5 text-gray-500">NSW to QLD - 107 MW</td>
                                    </tr>
                                    <tr class="bg-white">
                                        <td class="whitespace-nowrap text-sm leading-5 text-gray-500">QLD to NSW - 210 MW</td>
                                    </tr>
                                </td>
                                </tr>
                                <!-- Even row -->
                                <tr class="bg-gray-50">
                                <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                                    NSW1-QLD1
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-900">
                                    QNI
                                </td>
                                <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                    NSW & QLD
                                </td>
                                <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                    AC
                                </td>
                                <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                    2001
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                                    <tr class="bg-gray-50">
                                        <td class="whitespace-nowrap text-sm leading-5 text-gray-500">NSW to QLD - 600 MW</td>
                                    </tr>
                                    <tr class="bg-gray-50">
                                        <td class="whitespace-nowrap text-sm leading-5 text-gray-500">QLD to NSW - 1078 MW</td>
                                    </tr>
                                </td>
                                <!-- Odd row -->
                                <tr class="bg-white">
                                <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                                    VIC1-NSW1
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-900">
                                    VNI
                                </td>
                                <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                    VIC & NSW
                                </td>
                                <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                    AC
                                </td>
                                <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                    2008*

                                </td>
                                <td class="px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                                    <tr class="bg-white">
                                        <td class="whitespace-nowrap text-sm leading-5 text-gray-500">VIC to NSW - 1600 MW</td>
                                    </tr>
                                    <tr class="bg-white">
                                        <td class="whitespace-nowrap text-sm leading-5 text-gray-500">NSW to VIC - 1350 MW</td>
                                    </tr>
                                </td>
                                </tr>
                                <!-- Even row -->
                                <tr class="bg-gray-50">
                                <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                                    T-V-MNSP1
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-900">
                                    Basslink
                                </td>
                                <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                    TAS & VIC
                                </td>
                                <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                    DC
                                </td>
                                <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                    2006
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                                    <tr class="bg-gray-50">
                                        <td class="whitespace-nowrap text-sm leading-5 text-gray-500">TAS to VIC - 594 MW</td>
                                    </tr>
                                    <tr class="bg-gray-50">
                                        <td class="whitespace-nowrap text-sm leading-5 text-gray-500">VIC to TAS - 478 MW</td>
                                    </tr>
                                </td>
                                </tr>
                                <!-- Odd row -->
                                <tr class="bg-white">
                                <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                                    V-SA
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-900">
                                    Heywood
                                </td>
                                <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                    VIC & SA
                                </td>
                                <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                    AC
                                </td>
                                <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                    1988
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                                    <tr class="bg-white">
                                        <td class="whitespace-nowrap text-sm leading-5 text-gray-500">VIC to SA - 600 MW</td>
                                    </tr>
                                    <tr class="bg-white">
                                        <td class="whitespace-nowrap text-sm leading-5 text-gray-500">SA to VIC - 500 MW</td>
                                    </tr>
                                </td>
                                </tr>
                                <!-- Even row -->
                                <tr class="bg-gray-50">
                                <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                                    V-S-MNSP1
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-900">
                                    Murraylink
                                </td>
                                <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                    VIC & SA
                                </td>
                                <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                    DC
                                </td>
                                <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                    2002
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                                    <tr class="bg-gray-50">
                                        <td class="whitespace-nowrap text-sm leading-5 text-gray-500">VIC to SA - 220 MW</td>
                                    </tr>
                                    <tr class="bg-gray-50">
                                        <td class="whitespace-nowrap text-sm leading-5 text-gray-500">SA to VIC - 200 MW</td>
                                    </tr>
                                </td>
                                </tr>
                            </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <p>* VNI replaced the SNOWY1 and V-SN interconnectors when the SNOWY region was abolished. </p>
            <br/>
            <p>Note that nominal capacity refers to the optimal capacity or maximum transmission capcity of the infrastrcucture. The effective capacity of the interconecctors can and does fluctuate often as a result of <router-link to="/complex-market-concepts/constraints" class="text-blue-600 hover:underline">constraint equations</router-link>. </p>
            <br/>
            <p>When the AEMO originally publish the data, the flow of each Interconnector is a decimal number (i.e. a number that can be fractional, and postive or negative).  Convention dictates that flow on interconectors is denoted as Hobart-centric, hence: </p>
            <ol class="pl-16 list-disc">
                <li>Flow to the north and west is positive. </li>
                <ol class="pl-8">
                    <li>e.g. If there was 150MW flow from VIC to NSW, the flow on VNI would be 150MW</li>
                    <li>e.g. If there was 200MW flow on VIC to SA, the flow on Heywood would be 200MW</li>
                </ol>
                <li>Flow to the south and east is negative. </li>
                <ol class="pl-8">
                    <li>e.g. If there was 150MW flow from NSW to VIC, the flow on VNI would be -150MW</li>
                    <li>e.g. If there was 200MW flow from SA to VIC, the flow on Heywood would be -200MW</li>
                </ol>
            </ol>
            <br/>
            <p>In some market visualisation software, the interconnector flow is shown in this raw format (like in our NEMwatch software, in left of the image below). In other cases the interconnector flow is shown as a whole number with graphical arrows showing the flow (like in our ez2view software, in the right of the image below).</p>
            <br/>
            <img class="mx-auto w-full sm:w-1/3" src="@/assets/regions-image4.png" alt="NEM Regions & Interconnectors">
            <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Islanding events</h3>
            <p>The term ‘islanding’ is often used interchangeably to refer to one of three different types of events that can occur in the NEM: </p>
            <ol class="pl-16 list-decimal">
                <li><b>Economic Islanding</b> – When all (AC and/or DC) interconnectors between two regions are constrained, economic islands are formed on either side as regional spot prices can separate (sometimes significantly). E.g. If VNI was the only interconnector in the NEM to be bound, NSW & QLD would form one economic island and VIC, SA & TAS would form another. </li>
                <li><b>Frequency Islanding</b> – When one or more regions lose frequency synchronisation, generally because an AC interconnector(s) is offline, a frequency island forms. E.g. If Heywood is offline but Murraylink is online, SA is frequency islanded.</li>
                <li><b>Electrical Islanding</b> – When all (AC and/or DC) interconnectors linked to a region are offline, an electrical island forms. E.g. If QNI and Terranora are both offline simultaneously, QLD is electrically islanded.</li>
            </ol>
            
            <h2 class="mt-16 mb-6 text-4xl leading-4 font-bold tracking-tight text-grblack sm:text-4xl sm:leading-8">Analysing Regions and Interconnectors in ez2view</h2>
            <div class="w-full md:w-6/7 mx-auto p-8 rounded-md">
                <div class="shadow-md rounded-md">
                    <div class="tab w-full overflow-hidden border-t hover:bg-gray-100">
                    <input class="absolute opacity-0" id="tab-multi-one" type="checkbox" name="tabs">
                    <label class="block p-5 leading-normal cursor-pointer font-bold" for="tab-multi-one">Regional Schematics Widget</label>
                    <div class="tab-content overflow-hidden border-l-2 bg-gray-50 leading-normal">
                        <div class="p-5">
                            <img class="mx-auto w-2/5" src="@/assets/regionalschematics-image1.png" alt="Regional Schematics Widget">
                            <br/>
                            <p>For each region, the 'Regional Schematics' widget dispays an overview of all stations and interconnector flow. Further details are shown about intra-zonal flows, price-setter, const </p>
                            <br/>
                            <p></p>
                            <br/>
                            <p>More information can be found in the <router-link to="/widget-guides/v9.5/regional-schematics" class="text-blue-600 hover:underline">Regional Schematics widget guide</router-link>.</p>
                        </div>
                    </div>
                    </div>
                    <div class="tab w-full overflow-hidden border-t hover:bg-gray-100">
                    <input class="absolute opacity-0" id="tab-multi-two" type="checkbox" name="tabs">
                    <label class="block p-5 leading-normal cursor-pointer font-bold" for="tab-multi-two">Interconnector Details Widget</label>
                    <div class="tab-content overflow-hidden border-l-2 bg-gray-50 leading-normal">
                        <div class="p-5">
                            <img class="mx-auto w-1/2" src="@/assets/interconnectordetails-image1.png" alt="Constraint Sets Widget">
                            <br/>
                            <p>There is a 'regional schematics' widget for each region. The widget itself shows a visual display of </p>
                        </div>
                    </div>
                    </div>
                    <div class="tab w-full overflow-hidden border-t hover:bg-gray-100">
                    <input class="absolute opacity-0" id="tab-multi-three" type="checkbox" name="tabs">
                    <label class="block p-5 leading-normal cursor-pointer font-bold" for="tab-multi-three">Regional Consumption Widget</label>
                    <div class="tab-content overflow-hidden border-l-2 bg-gray-50 leading-normal">
                        <div class="p-5">
                            <img class="mx-auto w-1/2" src="@/assets/regionalconsumption-image1.png" alt="Market Notices Widget">
                            <br/>
                            <p>The easiest way to understandin</p>
                        </div>
                    </div>
                    </div>
                    <div class="tab w-full overflow-hidden border-t hover:bg-gray-100">
                    <input class="absolute opacity-0" id="tab-multi-four" type="checkbox" name="tabs">
                    <label class="block p-5 leading-normal cursor-pointer font-bold" for="tab-multi-four">Regional Trends Widget</label>
                    <div class="tab-content overflow-hidden border-l-2 bg-gray-50 leading-normal">
                        <div class="p-5">
                            <img class="mx-auto w-1/2" src="@/assets/regionaltrends-image2.png" alt="Market Notices Widget">
                            <br/>
                            <p>The easiest way to understandin</p>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        <h2 class="mt-16 mb-4 text-4xl leading-12 font-bold tracking-tight text-grblack lg:leading-4" id="inthenem">Further Reading</h2>
        <p>Over on WattClarity, we have been working to provide detailed explainers and analysis pieces on regions and interconnectors in the NEM. Below you will find links to some of our covergage on the topic:
                <ol class="pl-16 pt-2 pb-2">
                    <li>• <b><a href="https://www.aemo.com.au/-/media/Files/Electricity/NEM/Security_and_Reliability/Congestion-Information/2017/Interconnector-Capabilities.pdf" class="text-grblue hover:underline">Interconnector Capabilities  </a></b> by <b>AEMO</b>, November 2017 <span class="flex-shrink-0 inline-block ml-2 px-2 py-0.5 text-green text-xs leading-4 font-medium bg-green-300 rounded-full">on WattClarity</span></li>
                    <li>• <b><a href="https://wattclarity.com.au/articles/2020/03/surviving-on-the-island-again/" class="text-grblue hover:underline">Surviving on the island – again  </a></b> by <b>Allan O'Neil</b>, March 3<sup>rd</sup> 2020 </li>
                    <li>• <b><a href="https://wattclarity.com.au/articles/2020/10/which-state-pays-for-interconnectors/" class="text-grblue hover:underline">Which state pays for interconnectors?  </a></b> by <b>Ben Skinner</b>, October 6<sup>th</sup> 2020 </li>
                    <li>• <b><a href="https://wattclarity.com.au/articles/2022/12/how-common-are-islandings-and-transmission-tower-failures-in-the-nem/" class="text-grblue hover:underline">How common are islandings and transmission tower failures in the NEM? </a></b> by <b>Dan Lee</b>, December 16<sup>th</sup> 2022 </li>
                </ol>
            </p>
        </div>
    </div>
    </div>
</template>

<script>
export default {
    title: 'Regions & Interconnectors'
};
</script>