import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import { RouteConfigSingleView } from 'vue-router/types/router';
import siteMap from '@/router/siteMap';

Vue.use(VueRouter);

const flatten = (items: Array<RouteConfigSingleView>, prefix: string): RouteConfig[] => {
  return items.reduce((acc: RouteConfigSingleView[], item: RouteConfigSingleView) => {
    const route = {
      name: item.name,
      path: `${prefix ? '/' + prefix : ''}/${item.path}`,
      component: item.component
    } as RouteConfigSingleView;
    acc.push(route);

    if (item.children && item.children.length) {
      acc = acc.concat(flatten(item.children, item.path));
    }

    return acc;
  }, []);
};

export const routes: Array<RouteConfig> = [
  { path: '/download/ez2view-installer', redirect: '/getting-started/ez2viewinstaller' },
  { path: '/ez2view-installer', redirect: '/getting-started/ez2viewinstaller' },
  { path: '/widget-guides/:version/time-travel', redirect: '/design-principles/pointintime' },
  { path: '/widget-guides/:version/explorer', redirect: '/design-principles/ez2viewexplorer' },
  { path: '/widget-guides/:version/widget-bookmark-editor', redirect: '/design-principles/quickaccessbuttons' },
  { path: '/widget-guides/:version/application-layout-editor', redirect: '/design-principles/builddashboard' },
  { path: '/widget-guides/:verison/sharing-editor', redirect: '/design-principles/sharingpreferences' },
  { path: '/timeline/2021/02/version-9-planning', redirect: '/timeline/2021/02/version-9-release' },
  { path: '/release-notes/:version', redirect: '/redirect-release-notes' },
  ...flatten(siteMap, '')
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
});

export default router;
