<template>
    <div class="relative py-16 bg-white overflow-hidden">
    <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
    </div>
    <div class="relative px-4 sm:px-6 lg:px-8">
        <div class="text-lg mb-2 mx-auto">
            <router-link to="/design-principles" class="block"><p class="text-base text-center leading-4 text-grblue font-semibold tracking-wide hover:underline">Design Principles</p></router-link>
        </div>
        <nav class="border-t border-gray-200 px-4 flex items-center justify-between sm:px-0">
            <div class="-mt-px w-0 flex-1 flex">
                <router-link to="/design-principles/hyperlinks" class="border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-md font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300">
                    <!-- Heroicon name: arrow-narrow-left -->
                    <svg class="mr-3 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z" clip-rule="evenodd" />
                    </svg>
                    <p>Previous: <b> Context-Sensitive Linkages</b></p>
                </router-link>
            </div>
            <div class="hidden md:-mt-px md:flex">
            </div>
            <div class="-mt-px w-0 flex-1 flex justify-end">
                <router-link to="/design-principles/inbuiltalerts" class="border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-md font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300">
                    <p>Next: <b> Alerting Colours and Icons</b></p>
                    <!-- Heroicon name: arrow-narrow-right -->
                    <svg class="ml-3 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd" />
                    </svg>
                </router-link>
            </div>
        </nav>
        <div class="text-lg mx-auto mb-6">
            <h1 class="mt-6 mb-10 text-4xl text-center leading-10 font-extrabold tracking-tight text-grblack sm:text-5xl sm:leading-10">ez2view Explorer</h1>
            <p class="text-xl text-gray-500 leading-8 font-semibold">The ez2view explorer is your central point of navigation when you want to open a new widget or layout.</p>
        </div>
        <div class="text-gray-500 mx-auto leading-7 font-normal text-lg">
            <p>You can access the explorer at any time by clicking on the ‘+’ button at the top of any widget frame on your screen. </p>
            <br/>
            <img class="w-full lg:w-1/5 rounded-m mx-auto" src="@/assets/ez2viewexplorer-image1.png" alt="+ Button">
            <br/>
            <p>Once opened, you can explore a categorised list of all available widgets and layouts. The left panel of the explorer allows you to browse these, while the right panel provides a preview and summary of what is included.</p>
            <br/>
            <img class="w-full lg:w-2/3 rounded-m mx-auto" src="@/assets/ez2viewexplorer-image2.png" alt="ez2view Explorer">
            <br/>
            <p>Additionally, you can search through all available widgets in the search bar by entering keywords. Here you can enter a widget name, the name of a unit, station or other keywords to help find what you are looking for.</p>
            <br/>
            <img class="w-full lg:w-1/2 rounded-m mx-auto" src="@/assets/ez2viewexplorer-image3.png" alt="ez2view Explorer Search">
        </div>
    </div>
    </div>
</template>

<script>
export default {
    title: 'ez2view Explorer'
};
</script>