<template>
    <div class="relative py-16 bg-white overflow-hidden">
    <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
    </div>
    <div class="relative px-4 sm:px-6 lg:px-8">
        <div class="text-lg mx-auto mb-6 pb-6 border-b-1">
            <router-link to="/complex-market-concepts" class="block"><p class="text-base text-center leading-4 text-grblue font-semibold tracking-wide hover:underline">Complex Market Concepts</p></router-link>
            <h1 class="mt-6 mb-10 text-4xl text-center leading-10 font-extrabold tracking-tight text-grblack sm:text-5xl sm:leading-10">Constraints</h1>
            <p class="text-xl text-gray-500 leading-8 font-semibold">In any electrical grid there are limits to the maximum capability of the transmission infrastructure that transports the electricity. In order to ensure that the NEM is always operating within it's physical limits, the AEMO publishes 'constraint equations' to model power system congestion. These constraints can have a significant impact on pricing and dispatch in the market. </p>
            <br/>
            <p class="text-xl text-gray-500 leading-8 font-semibold">Below we have provided a basic introduction to constraints, how they work in the NEM and how you can analyse them in ez2view.</p>
        </div>
        <div class="text-gray-500 mx-auto leading-7 font-normal text-lg">
            <h2 class="mt-16 mb-4 text-4xl leading-12 font-bold tracking-tight text-grblack lg:leading-4">What are constraints?</h2>
            <p>The physical grid that supports the NEM is an interconnected piece of electrical and mechanical machinery that stretches thousands of kilometres.  This is governed by complex sets of engineering principles.. <p>
            <br/>
            <p>In the NEM, the AEMO obtains these network limitations from Transmission Network Service Providers (TNSPs), in the form of limit equations or line/transformer rating values. The AEMO then publishes constraint equations that act as "enforcement tools" that ensure that the system is always operating within these limits. Each constraint equation comes in the form of a mathematical linear equation that consists of a Left Hand Side (LHS) and a Right Hand Side (RHS). The NEM Dispatch Engine (NEMDE) - the central core computer program that determines dispatch instructions for each generator, each dispatch interval - then interprets these constraint equations to ensure that the market solutions are within the physical limit of the power system. <sup>1</sup></p>
            <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Constraint Equations</h3>
            <p>Each constraint equation models a particular limit for a given power system configuration and this is detailed in the constraint equation description data. For example the constraint equation F_I+ML_L5_0400 has the description: “Out = Nil, Lower 5 min requirement for a NEM Load Event, ML = 400”: “Out = Nil” indicates this is a system normal (i.e. Nil or no outages) constraint equation and is always invoked. If this constraint equation was for an outage condition the item of plant out would be in place of the “Nil”. E.g. Out = Georgetown to Sheffield 220 kV line. The rest of the description details the system condition the constraint equation is used for; in this case, it is for the frequency control ancillary services (FCAS) requirements for loss of a 400 MW load.</p>
            <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">How constraints are named</h3>
            Each constraint equation is given a unique identifier. The AEMO maintains a naming convention that it follows to name each equation, set and function based on related information about the constraint. The constraint naming guideline document is available on the <a href="https://aemo.com.au/en/energy-systems/electricity/national-electricity-market-nem/system-operations/congestion-information-resource"> AEMO Congestion Information Resource page</a>.  
            <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Bound constraints</h3>
            <p>A constraint equation is binding when the dispatch engine targets the summation of the Left Hand Side (LHS) terms to be equal with the constraint Right Hand Side (RHS) value. <sup>1</sup></p>
            <br/>
            <h2 class="mt-16 mb-6 text-4xl leading-4 font-bold tracking-tight text-grblack sm:text-4xl sm:leading-8">The widgets that relate to constraints in ez2view</h2>
            <div class="w-full md:w-6/7 mx-auto p-8 rounded-md">
                <div class="shadow-md rounded-md">
                    <div class="tab w-full overflow-hidden border-t hover:bg-gray-100">
                    <input class="absolute opacity-0" id="tab-multi-one" type="checkbox" name="tabs">
                    <label class="block p-5 leading-normal cursor-pointer font-semibold" for="tab-multi-one">Bound Constraints Widget</label>
                    <div class="tab-content overflow-hidden border-l-2 bg-gray-50 leading-normal">
                        <div class="p-5">
                            <img class="mx-auto w-1/3" src="@/assets/constraints-image1.png" alt="Bound Constraints Widget">
                            <br/>
                            <p>You can use the Bound Constraint Widget to view a hierachical display of all the constraints that are bound in the current dispatch interval. The display groups constraints according to coverage, then by constraint set, then by constraint equation and then by left hand side terms. It's important to note that generally many constraint equations may be invoked at a given time, but only a small number are generally bound in any dispatch interval.</p>
                        </div>
                    </div>
                    </div>
                    <div class="tab w-full overflow-hidden border-t hover:bg-gray-100">
                    <input class="absolute opacity-0" id="tab-multi-two" type="checkbox" name="tabs">
                    <label class="block p-5 leading-normal cursor-pointer font-semibold" for="tab-multi-two">Constraint Equations Widget</label>
                    <div class="tab-content overflow-hidden border-l-2 bg-gray-50 leading-normal">
                        <div class="p-5">
                            <img class="mx-auto w-1/2" src="@/assets/constraints-image2.png" alt="Constraint Sets Widget">
                            <br/>
                            <p>The Constraint Equations Widget shows details and graphs of all constraint equations that have recently been invoked, are invoked now, or are scheduled to be invoked in pre-dispatch - depending on your configurations. The screen is divided into two areas: the top panel is dynamically populated with constraint equations that match your selection criteria, and the lower panel is populated with a fixed set of constraint equations that you have specifically selected.</p>
                        </div>
                    </div>
                    </div>
                    <div class="tab w-full overflow-hidden border-t hover:bg-gray-100">
                    <input class="absolute opacity-0" id="tab-multi-three" type="checkbox" name="tabs">
                    <label class="block p-5 leading-normal cursor-pointer font-semibold" for="tab-multi-three">Constraint Sets Widget</label>
                    <div class="tab-content overflow-hidden border-l-2 bg-gray-50 leading-normal">
                        <div class="p-5">
                            <img class="mx-auto w-1/2" src="@/assets/constraints-image3.png" alt="Constraint Sets Widget">
                            <br/>
                            <p>The Constraint Set Details view shows details about the selected constraint set; including constraint equations belonging to this set and the sets invocation schedule.</p>
                        </div>
                    </div>
                    </div>
                    <div class="tab w-full overflow-hidden border-t hover:bg-gray-100">
                    <input class="absolute opacity-0" id="tab-multi-four" type="checkbox" name="tabs">
                    <label class="block p-5 leading-normal cursor-pointer font-semibold" for="tab-multi-four">Dispatch Constraints Widget</label>
                    <div class="tab-content overflow-hidden border-l-2 bg-gray-50 leading-normal">
                        <div class="p-5">
                            <img class="mx-auto w-1/2" src="@/assets/constraints-image4.png" alt="Constraint Sets Widget">
                            <br/>
                            <p>The Dispatch Constraints screen shows details of all Constraint Equations that are currently invoked and allows for filtering and export to CSV. </p>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Further Reading</h3>
            <p>Over on WattClarity, we have been working to provide detailed explainers and analysis pieces on network constraints in the NEM. Below you will find links to some of our covergage on the topic:
                <ol class="pl-12 pt-2 pb-2 list-disc">
                    <li><b><a href="http://www.wattclarity.com.au/other-resources/explanations/glossary/constraints/" class="text-grblue hover:underline">An explainer to constraint equations and constraint sets</a></b> by <b>Paul McArdle</b> </li>
                    <li><b><a href="http://www.wattclarity.com.au/articles/2020/11/casestudy-x5-constraint/" class="text-grblue hover:underline">Case Study: How to interpret a new NEM contraint and guess what it might do</a></b> by <b>Allan O'Neil</b> </li>
                </ol>
            </p>

            <br/>
            <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">References</h3>
            <ol class="pl-15 pt-2 pb-2">
                <li><sup>1</sup> <a href="https://aemo.com.au/en/energy-systems/electricity/national-electricity-market-nem/system-operations/congestion-information-resource/constraint-faq" class="text-grblue hover:underline">AEMO Constraints FAQ</a></li>
                <li><sup>2</sup> <a href="https://aemo.com.au/en/energy-systems/electricity/national-electricity-market-nem/system-operations/congestion-information-resource/constraint-faq" class="text-grblue hover:underline">AEMO Congestion Information Resources</a></li>
            </ol>
            

        </div>
    </div>
    </div>
</template>

<script>
export default {
    title: 'Constraints'
};
</script>

<style scoped>
    /* Tab content - closed */
    .tab-content {
    max-height: 0;
    -webkit-transition: max-height .35s;
    -o-transition: max-height .35s;
    transition: max-height .35s;
    }
    /* :checked - resize to full height */
    .tab input:checked ~ .tab-content {
    max-height: 200vh;
    }
    /* Label formatting when open */
    .tab input:checked + label{
    /*@apply text-xl p-5 border-l-2 border-indigo-500 bg-gray-100 text-indigo*/
    font-size: 1.25rem; /*.text-xl*/
    padding: 1.25rem; /*.p-5*/
    border-left-width: 2px; /*.border-l-2*/
    border-color: #403b41; /*.border-indigo*/
    background-color: #d1d5db; /*.bg-gray-300 */
    color: #403b41; /*.text-indigo*/
    }
    /* Icon */
    .tab label::after {
    float:right;
    right: 0;
    top: 0;
    display: block;
    width: 1.5em;
    height: 1.5em;
    line-height: 1.5;
    font-size: 1.25rem;
    text-align: center;
    -webkit-transition: all .35s;
    -o-transition: all .35s;
    transition: all .35s;
    }
    /* Icon formatting - closed */
    .tab input[type=checkbox] + label::after {
    content: "+";
    font-weight:bold; /*.font-bold*/
    border-width: 1px; /*.border*/
    border-radius: 9999px; /*.rounded-full */
    border-color: #b8c2cc; /*.border-grey*/
    }
    .tab input[type=radio] + label::after {
    content: "\25BE";
    font-weight:bold; /*.font-bold*/
    border-width: 1px; /*.border*/
    border-radius: 9999px; /*.rounded-full */
    border-color: #b8c2cc; /*.border-grey*/
    }
    /* Icon formatting - open */
    .tab input[type=checkbox]:checked + label::after {
    transform: rotate(315deg);
    background-color: #e53e45; 
    color: #f8fafc; /*.text-grey-lightest*/
    }
    .tab input[type=radio]:checked + label::after {
    transform: rotateX(180deg);
    background-color: #e53e45; 
    color: #f8fafc; /*.text-grey-lightest*/
    }
</style>