<template>
  <div class="relative py-16 bg-white overflow-hidden">
    <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full"></div>
    <div class="relative px-4 sm:px-6 lg:px-8">
        <div class="text-lg mx-auto mb-6">
            <router-link to="/widget-guides" class="block"><p class="text-base text-center leading-4 text-grblue font-semibold tracking-wide hover:underline">Widget Guides</p></router-link>
            <h1 class="mt-4 mb-10 text-4xl text-center leading-10 font-extrabold tracking-tight text-grblack sm:text-5xl sm:leading-10">Portfolio Details</h1>
            <p class="text-xl text-gray-500 leading-8 font-semibold">The Portfolio Details widget shows operating data for all the associated units for an individual portfolio owner in the NEM. This widget is especially useful to understand the ownership of assets in the NEM, and to see an up-to-date overview of the operations of these asset owners. </p>
            <div class="text-gray-500 mx-auto leading-7 font-normal text-lg">
                <br/>
                <img class="w-2/3 rounded-m mx-auto" src="@/assets/portfoliodetails-image1.png" alt="Portfolio Details Widget">
                <br/>
                <h2 class="mt-16 mb-4 text-4xl leading-12 font-bold tracking-tight text-grblack lg:leading-4">Description</h2>
                <p>The Portfolio Details widget shows overviews details about all of the units associated with the portfolio owner for the current point in time. For the purposes of this widget, a 'portfolio owner' is used to describe the organisation that is paid the spot price for the generation output. </p>
                <br/>
                <p>A 24 hour rolling window of aggregate generation for all of the units in the portfolio is displayed at the top of the widget. Hovering the mouse over this chart will allow you to see individual output values for each unit along with the aggregate total value.</p>
                <br/>
                <p>The units that belong to this portfolio at the time shown at the top of the widget are displayed in the unit details section in the lower panel of the widget. All of the units belonging to the portfolio are listed along with information related to the current operating status of each unit including current generation, previous generation, change in generation (MW and %) and the constrained state of the unit (whether it is on the LHS of a bound constraint equation). </p>
                <br/>
                <img class="w-2/3 rounded-m mx-auto" src="@/assets/portfoliodetails-image3.png" alt="Portfolio Details Widget">
                <br/>
                <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Portfolio Owner</h3>
                <br/>
                <img class="w-1/4 rounded-m mx-auto" src="@/assets/portfoliodetails-image4.png" alt="Portfolio Details Widget - Prtofolio Owner">
                <br/>
                <p>In the top left hand corner of the widget you will see the name of the portfolio owner that the widget is displaying data for. Through the <router-link to="/design-principles/ez2viewexplorer" class="inline text-blue-600 hover:underline">ez2view Explorer</router-link>, you can search for and then open this widget for eac major portfolio owners in the NEM.  </p>
                <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Portfolio Summary Stats</h3>
                <br/>
                <img class="w-2/3 rounded-m mx-auto" src="@/assets/portfoliodetails-image2.png" alt="Portfolio Details Widget - Summary Stats">
                <br/>
                <p>The summary stats panel is shown at the top of the widget and shows aggregate totals for units and loads associated to the portfolio owner. The table below outlines the fields displayed in this panel: </p>
                <div class="mx-auto flex flex-col">
                <div class="mx-auto">
                    <div class="mx-auto px-8 py-8 align-middle inline-block sm:px-8 lg:px-8">
                        <div class="mx-auto shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                            <table class="mx-auto divide-y divide-gray-200">
                                <thead>
                                    <tr>
                                    <th class="px-2 py-2 bg-gray-100 leading-2 text-grblack">
                                        <p class="text-lg text-center font-bold">Field</p>
                                    </th>
                                    <th class="px-2 py-2 bg-gray-100 leading-4 text-grblack">
                                        <p class="text-lg text-center font-bold">Description</p>
                                    </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="bg-white">
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                                        Units Running
                                    </td>
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                        The number of units associated with the portfolio generating >0MW againt the total number of units.
                                    </td>
                                    </tr>
                                    <tr class="bg-white">
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                                        Reg. Gen Capacity
                                    </td>
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                        The aggregate total of registered capacity of all associated units.
                                    </td>
                                    </tr>
                                    <tr class="bg-white">
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                                        Current Gen
                                    </td>
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                        The aggregate total of actual output of all associated units. 
                                    </td>
                                    </tr>
                                    <tr class="bg-white">
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                                        Change in Gen
                                    </td>
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                        The aggregated change in output of all associated units from the previous dispatch interval.
                                    </td>
                                    </tr>
                                    <tr class="bg-white">
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                                        Change in Gen %
                                    </td>
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                        The aggregated change in output as a percentage, of all associated units from the previous dispatch interval.
                                    </td>
                                    </tr>
                                    <tr class="bg-white">
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                                        Reg. Load Capacity
                                    </td>
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                        The aggregate total of registered capacity of all associated loads.
                                    </td>
                                    </tr>
                                    <tr class="bg-white">
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                                        Current Load
                                    </td>
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                        The aggregate total of actual output of all associated loads.
                                    </td>
                                    </tr>
                                    <tr class="bg-white">
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                                        Change in Load
                                    </td>
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                        The aggregated change in output of all associated loads from the previous dispatch interval.
                                    </td>
                                    </tr>
                                    <tr class="bg-white">
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                                        Change in Load %
                                    </td>
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                        The aggregated change in output as a percentage, of all associated units from the previous dispatch interval.
                                    </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                </div>
                <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">List of Units</h3>
                <br/>
                <img class="w-1/2 rounded-m mx-auto" src="@/assets/portfoliodetails-image5.png" alt="Portfolio Details Widget - Summary Stats">
                <br/>
                <p>The left panel shows a full list of all units associated with the portfolio owner. For each associated unit, the following data is displayed in the table: </p>
                <div class="mx-auto flex flex-col">
                <div class="mx-auto">
                    <div class="mx-auto px-8 py-8 align-middle inline-block sm:px-8 lg:px-8">
                        <div class="mx-auto shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                            <table class="mx-auto divide-y divide-gray-200">
                                <thead>
                                    <tr>
                                    <th class="px-2 py-2 bg-gray-100 leading-2 text-grblack">
                                        <p class="text-lg text-center font-bold">Field</p>
                                    </th>
                                    <th class="px-2 py-2 bg-gray-100 leading-4 text-grblack">
                                        <p class="text-lg text-center font-bold">Description</p>
                                    </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="bg-white">
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                                        DUID
                                    </td>
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                        The ID of unit.
                                    </td>
                                    </tr>
                                    <tr class="bg-white">
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                                        Current MW
                                    </td>
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                        The total of actual output of the unit in the current dispatch interval. 
                                    </td>
                                    </tr>
                                    <tr class="bg-white">
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                                        Previous MW
                                    </td>
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                        The total of actual output of the unit in the previous dispatch interval. 
                                    </td>
                                    </tr>
                                    <tr class="bg-white">
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                                        Change (MW)
                                    </td>
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                        The change in output of the unit from the previous dispatch interval.
                                    </td>
                                    </tr>
                                    <tr class="bg-white">
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                                        Change (%)
                                    </td>
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                        The change in output as a percentage, of the unit from the previous dispatch interval.
                                    </td>
                                    </tr>
                                    <tr class="bg-white">
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                                        Constrained State
                                    </td>
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                        Constraints applicable to the unit in the current dispatch interval. 
                                    </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                </div>
                <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Unit Details</h3>
                <br/>
                <img class="w-1/2 rounded-m mx-auto" src="@/assets/portfoliodetails-image6.png" alt="Portfolio Details Widget - Summary Stats">
                <br/>
                <p>Select an individual unit from the list of units panel (left panel), and the right panel (unit details panel) will display further details for the chosen unit. 
                   The 'Unit Details' panel, shows information about the unit as it has been registered with the AEMO.</p>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  title: 'Portfolio Details'
};
</script>
