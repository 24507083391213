<template>
  <div class="relative py-16 bg-white overflow-hidden">
    <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full"></div>
    <div class="relative px-4 sm:px-6 lg:px-8">
      <div class="text-lg mx-auto mb-6">
        <router-link to="/widget-guides" class="block"><p class="text-base text-center leading-4 text-grblue font-semibold tracking-wide hover:underline">Widget Guides</p></router-link>
        <h1 class="mt-4 mb-10 text-4xl text-center leading-10 font-extrabold tracking-tight text-grblack sm:text-5xl sm:leading-10">Regional Trends</h1>
        <p class="text-xl text-gray-500 leading-8 font-semibold">
          The Regional Trends widget display generation availability, demand and price time series charts for each region of the NEM.
        </p>
        <div class="text-gray-500 mx-auto leading-7 font-normal text-lg">
          <br />
          <img class="w-2/3 rounded-m mx-auto" src="@/assets/regionaltrends-image2.png" alt="Image of the stacked regional trends widget" />
          <br />
          <h2 class="mt-16 mb-4 text-4xl leading-12 font-bold tracking-tight text-grblack lg:leading-4">Description</h2>
          <p>Each chart shows region-specific generation availability (light grey area), demand (grey area) and price (black line) where:</p>
          <ul class="pl-8 pt-2 pb-2">
                            <li>
                              • <b>Availability</b> : The total available generation 
                capacity (in MW) summed for all units in the region as declared by each generator (as generated).
                            </li>
                            <li>
                              • <b>Demand</b>: The total of non-dispatchable load for the region (in MW) as met by scheduled generators.
                            </li>
                            <li>
                              • <b>RRP</b>: The Regional Reference Price, shown as the line series, represents the five or thirty minute pool price for the Region (see below).
                            </li>
                          </ul>
         
          <br />
          <p>The current point in time is indicated by a vertical line. Dispatch interval data is displayed for historical periods and points within the 5-minute predispatch forecast timeframe. Predispatch 30-minute forecasts allow the chart series to extend to the start of the next NEM trading day.
            </p>
            <br />
          <img class="w-2/3 rounded-m mx-auto" src="@/assets/regionaltrends.png" alt="Image of the regional trends widget" />
          <br />
          <p>If you hover your mouse cursor over the chart a pop-up appears showing the numeric values for the time period under the cursor.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  title: 'Regional Trends'
};
</script>
