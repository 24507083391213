<template>
    <div class="relative py-16 bg-white overflow-hidden">
    <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
    </div>
    <div class="relative px-4 sm:px-6 lg:px-8">
        <div class="text-lg mb-2 mx-auto">
            <router-link to="/design-principles" class="block"><p class="text-base text-center leading-4 text-grblue font-semibold tracking-wide hover:underline">Design Principles</p></router-link>
        </div>
        <nav class="border-t border-gray-200 px-4 flex items-center justify-between sm:px-0">
            <div class="-mt-px w-0 flex-1 flex">
                <router-link to="/design-principles/sharingpreferences" class="border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-md font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300">
                    <!-- Heroicon name: arrow-narrow-left -->
                    <svg class="mr-3 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z" clip-rule="evenodd" />
                    </svg>
                    <p>Previous: <b> Sharing Preferences</b></p>
                </router-link>
            </div>
            <div class="hidden md:-mt-px md:flex">
            </div>
            <div class="-mt-px w-0 flex-1 flex justify-end">
            </div>
        </nav>
        <div class="text-lg mx-auto mb-6">
            <h1 class="mt-6 mb-10 text-4xl text-center leading-10 font-extrabold tracking-tight text-grblack sm:text-5xl sm:leading-10">Adding Your Own Private AEMO Data</h1>
            <p class="text-xl text-gray-500 leading-8 font-semibold">If your organisation is a registered market participant with access to your own private AEMO data, you can configure ez2view to access this data within the application. The information below explains how to configure and leverage your private AEMO data, but we recommend that you first read <router-link to="/getting-started/DataDelivery" class="inline text-blue-600 hover:underline">our page about data delivery</router-link> to understand the different methods in which you can set-up ez2view.  </p>
        </div>
        <div class="text-gray-500 mx-auto leading-7 font-normal text-lg">
            <p>By default, ez2view works by pulling data from our Common Cloud MMS which contains only public AEMO data - we have done this so that users can quickly start using the software upon installation. </p>
            <br/>
            <p>However, we have purposely designed and built the software so that clients who have assets (units/stations), and hence significant market exposure, can leverage the valuable insight of viewing and analysing their own private operating data in real-time. </p>
            <br/>
            <p>Configuring ez2view to access your own private MMS data increases your visibility of your organisation's assets within the market. This gives ez2view the ability to load otherwise non-accessible data, allowing you to:</p>
            <ol class="pl-10 pt-2 pb-2 list-disc">
                <li>See bids in real time, and also bids in predispatch</li>
                <li>See targets in current day, and also in predispatch</li>
                <li>See private constraints</li>
                <li>See Semi-Dispatch Cap</li>
                <li>See derived Conformance Status</li>
                <li>See rebids</li>
            </ol>
            <br/>
            <div class="-my-2 sm:-mx-6 lg:-mx-8">
                <div class="px-8 py-8 align-middle inline-block sm:px-8 lg:px-8">
                <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                    <table class="min-w-full divide-y divide-gray-200">
                    <thead>
                        <tr>
                        <th class="w-1/3 px-2 py-2 bg-gray-300 leading-2 text-gray-700">
                            <p class="text-lg text-center font-semibold">Without Private Data</p>
                        </th>
                        <th class="w-1/3 px-2 py-2 bg-gray-300 leading-4 text-gray-700">
                            <p class="text-lg text-center font-semibold">With Private Data</p>
                        </th>
                        </tr>
                    </thead>
                    <tbody>
                        <!-- Odd row -->
                        <tr class="bg-gray-50">
                        <td class="px-6 py-4 text-sm leading-5 text-gray-600 text-center">
                            <br/>
                            <img class="rounded-m mx-auto" src="@/assets/privatedata-image7.png" alt="">
                            <br/>
                            <p>Right-click and select 'View Image' to see a larger version of this screenshot.</p>
                        </td>
                        <td class="px-6 py-4 text-sm leading-5 text-gray-600 text-center">
                            <br/>
                            <img class="rounded-m mx-auto" src="@/assets/privatedata-image8.png" alt="">
                            <br/>
                            <p>Right-click and select 'View Image' to see a larger version of this screenshot.</p>
                        </td>
                        </tr>
                    </tbody>
                    </table>
                </div>
                </div>
            </div>
            <br/>
            <p>We have designed two different methods of accessing private data in ez2view: </p>
            <ol class="pl-10 pt-2 pb-2 list-disc">
                <li><span class="font-bold text-grblack"><span class="text-grblue">Method 1A</span>: Private MMS</span> - You supply and manage your own MMS (with your private AEMO data) locally and set up a connection string to point ez2view at this database. You will find instructions on how to configure ez2view by this method below.</li>
                <li><span class="font-bold text-grblack"><span class="text-grblue">Method 1B</span>: Managed Private MMS</span> - We configure and manage your MMS (with your private AEMO data) for you via the cloud and point ez2view at this database. Please <router-link to="/contact-us" class="inline text-blue-600 hover:underline">contact us</router-link> directly if you would like to choose this method of data delivery.</li>
            </ol>
            <h2 class="mt-16 mb-4 text-4xl leading-12 font-bold tracking-tight text-grblack lg:leading-4">Configuring Your Own Database</h2>
            <p>We've written instructions on how to configure the database for ez2view to include your private data - these instructions are found in the install guide, and we link to this document on <router-link to="/getting-started/ez2viewinstaller" class="inline text-blue-600 hover:underline">our install page</router-link>. </p>
            <br/>
            <p>As noted above, these instructions pertain to users who have chosen <b>'Method 1A'</b> of Data Delivery as <router-link to="/getting-started/DataDelivery" class="inline text-blue-600 hover:underline">explained in more detail here</router-link>. Please note that your MMS database platform must be MS SQL or Oracle as these are the two platforms that the AEMO officially supports.</p>
            <br/>
            <p>You can <router-link to="/contact-us" class="inline text-blue-600 hover:underline">contact us</router-link> directly if you could like to configure a different method of data delivery, or if you have any issues configuring the connection.</p>
        </div>
    </div>
    </div>
</template>

<script>
export default {
    title: 'Private Data'
};
</script>