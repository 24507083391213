<template>
<!--
  Tailwind UI components require Tailwind CSS v1.8 and the @tailwindcss/ui plugin.
  Read the documentation to get started: https://tailwindui.com/documentation
-->
<!-- This component requires Tailwind CSS >= 1.5.1 and @tailwindcss/ui >= 0.4.0 -->
<div class="relative py-16 bg-white overflow-hidden">
  <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
  </div>
  <div class="relative px-4 sm:px-6 lg:px-8">
    <div class="text-lg mx-auto mb-6">
      <h1 class="mt-6 mb-10 text-4xl text-center leading-10 font-extrabold tracking-tight text-grblack sm:text-5xl sm:leading-10">Getting Started</h1>
      <p class="text-xl text-gray-500 leading-8 font-semibold">Using ez2view for the first time? <span style="padding-right:15px"/>The pages below will help you through your first steps with explanations on how the software is structured, how the underlying data is delivered, and how to download, install and log-in for the first time. </p>
    </div>
    <br/>

    <ul class="grid grid-cols-1 gap-6 lg:grid-cols-2 xl:grid-cols-3">

      <router-link to="/getting-started/accesspoints" class="block h-full">
      <li class="h-full col-span-1 bg-white rounded-lg shadow hover:bg-gray-100">
        <div class="w-full flex items-center justify-between p-6 space-x-6">
          <div class="flex-1">
            <div class="flex items-center space-x-3">
              <h3 class="text-grblack xl:text-xl leading-6 font-bold truncate hover-text-grblue lg:text-lg">Access Points</h3>
            </div>
            <p class="mt-1 text-gray-500 text-sm leading-5">Structure of ez2view Desktop and ez2view Online</p>
          </div>
        </div>
      </li>
      </router-link>

      <router-link to="/getting-started/datadelivery" class="block h-full">
      <li class="h-full col-span-1 bg-white rounded-lg shadow hover:bg-gray-100">
        <div class="w-full flex items-center justify-between p-6 space-x-6">
          <div class="flex-1">
            <div class="flex items-center space-x-3">
              <h3 class="text-grblack xl:text-xl leading-6 font-bold truncate hover-text-grblue lg:text-lg">Data Delivery</h3>
            </div>
            <p class="mt-1 text-gray-500 text-sm leading-5">Where is my data coming from?</p>
          </div>
        </div>
      </li>
      </router-link>

      <router-link to="/getting-started/activation" class="block h-full">
      <li class="h-full col-span-1 bg-white rounded-lg shadow hover:bg-gray-100">
        <div class="w-full flex items-center justify-between p-6 space-x-6">
          <div class="flex-1">
            <div class="flex items-center space-x-3">
              <h3 class="text-grblack xl:text-xl leading-6 font-bold truncate hover-text-grblue lg:text-lg">Activation</h3>
            </div>
            <p class="mt-1 text-gray-500 text-sm leading-5">Logging in after installation</p>
          </div>
        </div>
      </li>
      </router-link>

      <router-link to="/getting-started/ez2viewinstaller" class="block h-full">
      <li class="h-full col-span-1 bg-white rounded-lg shadow hover:bg-gray-100">
        <div class="w-full flex items-center justify-between p-6 space-x-6">
          <div class="flex-1">
            <div class="flex items-center space-x-3">
              <h3 class="text-grblack xl:text-xl leading-6 font-bold truncate hover-text-grblue lg:text-lg">ez2view Installer</h3>
            </div>
            <p class="mt-1 text-gray-500 text-sm leading-5">Install files for ez2view Desktop</p>
          </div>
        </div>
      </li>
      </router-link>

      <router-link to="/getting-started/ez2updateinstaller" class="block h-full">
      <li class="h-full col-span-1 bg-white rounded-lg shadow hover:bg-gray-100">
        <div class="w-full flex items-center justify-between p-6 space-x-6">
          <div class="flex-1">
            <div class="flex items-center space-x-3">
              <h3 class="text-grblack xl:text-xl leading-6 font-bold truncate hover-text-grblue lg:text-lg">ez2update Installer</h3>
            </div>
            <p class="mt-1 text-gray-500 text-sm leading-5">Install files for ez2update</p>
          </div>
        </div>
      </li>
      </router-link>

    </ul>

  </div>
</div>
</template>

<script>
export default {
    title: 'Getting Started'
};
</script>